<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <UserBanner></UserBanner>

    <div class="layout cont-box">
      <el-row style="width: 100%;">
        <!-- 菜单 -->
        <userNavs></userNavs>

        <!-- 内容 -->
        <el-col :span="19">
          <div class="main">
            <WelcomeModal  v-if="isFirstTime"></WelcomeModal>
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1" @click="getByuserprte">助学金卡</el-menu-item>
              <el-menu-item index="2" >会员卡</el-menu-item>
              <el-menu-item index="3" disabled>票</el-menu-item>
              <el-menu-item index="3" disabled>证件</el-menu-item>
            </el-menu>
            <div v-if="activeIndex==1" >
                <div class="coupon-list" v-if="flage">
                  <div v-for="(couponse, index) in couponse.data" :key="index" class="coupon-card">
                    <div class="logo-container">
                      <!-- 这里可以放置您的logo图片，使用img标签或者背景图片方式 -->
                      <img src="@/assets/images/logo1.jpg" alt="Your Logo" class="logo-image" />
                    </div>
                    <div class="coupon-header">
                         <h3>{{ couponse.preferential.name}}</h3>
                      <span class="coupon-expire">有效期至 {{ couponse.preferential.outtime}}</span>
                      </div>
                    <div class="coupon-body">
                        <p>{{couponse.preferential.threshold}}</p>
                      </div>
                    <div class="coupon-footer">
                        <span class="coupon-discount">{{ couponse.preferential.reduction }}% OFF</span>
                        <el-badge :value="`${couponse.countCa}`" :max="99" class="item">
                          <button @click="redeemCoupon(couponse)">立即使用</button>
                        </el-badge>
                      </div>
                  </div>
                </div>
              <el-pagination
                  @size-change="handleSizeChanges"
                  @current-change="handleCurrentChanges"
                  :current-page="couponse.current_page"
                  :page-sizes="[2, 5, 10, 20]"
                  :page-size="couponse.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="couponse.total">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
import WelcomeModal from '@/components/user/WelcomeModal.vue';
export default {
  components: {
    UserBanner,
    userNavs,
    WelcomeModal
  },
  data() {
    return {
      activeIndex: '0',
      isFirstTime: true,
      couponse: {
        per_page: 2,
        current_page: 1,
        data: []
      },
      flage:false,
    }
  },
  methods: {
    handleSizeChanges(val){
      this.couponse.per_page=val;
      this.getByuserprte();
    },
    handleCurrentChanges(val){
      this.couponse.current_page=val;
      this.getByuserprte();
    },
    // Inside your methods:
    getByuserprte() {
      let that = this;
      Api.Userprefe.Realpolicy({
        userName: that.$store.state.user_info.nickname,
        per_page: that.couponse.per_page,
        current_page: that.couponse.current_page,
      }).then(res => {
        // Directly assign new data to trigger reactivity
        that.couponse = {
          ...res.data.data, // Spread operator to create a new object reference
          per_page: that.couponse.per_page,
          current_page: that.couponse.current_page,
        };

        let promises = [];
        for (let i = 0; i < that.couponse.data.length; i++) {
          // Temporarily nullify preferential to indicate loading
          that.$set(that.couponse.data, i, {
            ...that.couponse.data[i],
            preferential: null
          }); // Use $set for Vue to track changes in array elements

          promises.push(
              Api.Preferential.Realpolicy({ id: that.couponse.data[i].preferentialid }).then(res => {
                // Ensure Vue knows about the nested property change
                that.$set(that.couponse.data[i], 'preferential', res.data);
              })
          );
        }

        Promise.all(promises).then(() => {
          // Force a re-render by toggling a data property
          that.flage = !that.flage;
        });
      });
    },

    handleSelect(key, keyPath) {
      this.activeIndex=key;
    },
    redeemCoupon(coupon) {
      this.$confirm('是否使用'+coupon.preferential.name+'优惠卷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // let count=null;
        // let statevv=null;
        // if ((coupon.countCa-1)==0){
        //   count=0;
        //   statevv=3;
        // }else {
        //   count=(coupon.countCa-1);
        //   statevv=0;
        // }
        // Api.Userprefe.updateRealpolicy({
        //   id:coupon.id,
        //   countCa:count,
        //   state:statevv,
        // }).then(res=>{
        //   this.getByuserprte();
        // })
        // this.$message({
        //   type: 'success',
        //   message: '已使用!'
        // });
        if (coupon.preferential.type==1){
          if (coupon.preferential.specifictype==2){
              this.$router.push({ path: '/school', query: { id: coupon.preferential.specifictid } });
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
  },
  mounted() {
    this.isFirstTime = true;
  },
  created() {
  }

}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/

.page{
  // padding: 20px 0;
  background: #f7f8fa;
  min-height: calc(100vh - 300px);
}

.cont-box{
  padding: 50px 0;
  display: flex;
}

.main{
  margin-left: 20px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 30px 20px;
}

.coupon-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.coupon-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.coupon-card:hover {
  transform: translateY(-5px);
}

.coupon-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.coupon-expire {
  float: right;
  color: #999;
}

.coupon-body {
  padding: 20px;
}

.coupon-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
}

.coupon-discount {
  color: #d4235c;
  font-weight: bold;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.container {
  position: relative; /* 使内部的定位元素（如logo）能够相对于此容器定位 */
}

.logo-container {
  position: absolute;
  top: 10px; /* 调整logo与顶部的距离 */
  right: 20px; /* 调整logo与右侧的距离 */
  z-index: 1; /* 确保logo在列表卡片之上 */
}

.logo-image {
  width: 50px; /* 根据实际logo大小调整 */
  height: auto;
  border-radius:50%
}
</style>