<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main">
                        <el-form :model="formData" :rules="ruleForm" ref="formData" label-width="130px">
                            <el-form-item label="旧密码：" prop="oldpassword">
                                <el-input type="password" v-model="formData.oldpassword" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item label="新密码：" prop="newpassword">
                                <el-input type="password" v-model="formData.newpassword" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item label="确认密码：" prop="repassword">
                                <el-input type="password" v-model="formData.repassword" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="button" class="btn-normal" @click="onSubmit">确认修改</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            formData: {
                oldpassword: '',
                newpassword: '',
                repassword: '',
            },
            ruleForm: {
                oldpassword: [{
                    required: true,
                    message: '请输入旧密码',
                    trigger: 'blur'
                }],
                newpassword: [{
                    required: true,
                    message: '请输入新密码',
                    trigger: 'blur'
                }],
                repassword: [{
                    required: true,
                    message: '请两次输入密码',
                    trigger: 'blur'
                }],
            },
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    methods: {
        //确定
        onSubmit(){
            let that = this;
            if(!that.formData.oldpassword || !that.formData.newpassword || !that.formData.repassword){
                that.$message.error('请输入内容再提交...');
                return false;
            }
            if(that.formData.newpassword !== that.formData.repassword){
                that.$message.error('两次密码不一样');
            }
            Api.User.ChangePwd(that.formData).then((res) => {
                if (res.data.code == 1) {
                    that.$message.success(res.data.msg);
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('loginStatus');
                    location.reload();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 20px;
}

</style>