<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main" v-if="!$store.state.userTrueAuth">
                        <div class="danger">
                            <img class="ico" src="../../assets/svg/danger-ico.svg" alt="">
                            请您先进行 <router-link class="link" to="/truename">实名认证</router-link>！！
                        </div>
                    </div> 
                    <div class="main" v-if="$store.state.userTrueAuth">
                        <div class="person">
                            <div class="content">
                                <div class="flex-box">
                                    <h2 class="name" @click="dialogVisible = true">{{ pageData.name ? pageData.name : '姓名' }}</h2>
                                    <div class="phone ml-20" @click="dialogVisible = true">{{ pageData.phone ? pageData.phone : '电话号码' }}</div>
                                </div>
                                <el-row style="margin-top: 20px;" @click="dialogVisible = true">
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">邮箱：{{ pageData.email ? pageData.email : '邮箱' }}</span>
                                    </el-col>
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">性别：{{ pageData.gender_text ? pageData.gender_text : '性别' }}</span>
                                    </el-col>
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">年龄：{{ pageData.age ? pageData.age : '年龄' }}</span>
                                    </el-col>
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">期望薪资：{{ pageData.salary ? pageData.salary : '期望薪资' }}</span>
                                    </el-col>
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">最高学历：{{ pageData.education_text ? pageData.education_text : '最高学历' }}</span>
                                    </el-col>
                                    <el-col class="el-item" :span="8" style="margin:10px 0">
                                        <span @click="dialogVisible = true">毕业状态：{{ pageData.seniors_state_text ? pageData.seniors_state_text : '在校/毕业' }}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="avatar">
                                <el-upload
                                    class="avatar-uploader" :action="$store.state.Config.Api_Url+'/common/upload'"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="pageData.avatar" :src="pageData.avatar" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </div>
                        </div>
                        <div class="card">
                            <div class="layout-title">求职意向
                                <span class="layout-more" @click="dialogJob">编辑</span>
                            </div>
                            <el-row style="margin-top: 20px;">
                                <el-col :span="12" style="margin:10px 0">意向岗位：{{ pageData.job ? pageData.job : '求职岗位' }}</el-col>
                                <el-col :span="12" style="margin:10px 0">求职类型：{{ pageData.to_type_text ? pageData.to_type_text : '全职/兼职' }}</el-col>
                                <el-col :span="12" style="margin:10px 0">意向城市：{{ pageData.area_text ? pageData.area_text : '意向城市' }}</el-col>
                                <el-col :span="12" style="margin:10px 0">求职状态：{{ pageData.current_state_text ? pageData.current_state_text : '求职状态' }}</el-col>
                            </el-row>
                        </div>
                        <div class="card">
                            <div class="layout-title">居住地
                                <span class="layout-more" @click="setAddress">编辑</span>
                            </div>
                            <div class="city">{{pageData.area_address ? pageData.area_address : '详细地址'}}</div>
                        </div>
                        <div class="card pro-images">
                            <div class="layout-title">职业资格证书
                                <span class="layout-more" @click="dialogVisible5 = true;dialogTitle5='添加证书'">添加证书</span>
                            </div>
                            <div class="content">
                                <el-upload action list-type="picture-card" :file-list="pageData.pro_images"
                                 :before-upload="beforeImagesUpload" :on-success="handleImagesSuccess">
                                    <i slot="default" class="el-icon-plus"></i>
                                    <div slot="file" slot-scope="{file}" style="height:100%">
                                        <img class="el-upload-list__item-thumbnail" :src="file.fullurl" alt="">
                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                            <i class="el-icon-zoom-in"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-edit" @click="handleUpdate(file)">
                                            <i class="el-icon-edit"></i>
                                            </span>
                                            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                            <i class="el-icon-delete"></i>
                                            </span>
                                        </span>
                                    </div>
                                </el-upload>
                                <el-dialog :title="imgDialogTitle" :visible.sync="imgDialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>    
                            </div>
                        </div>
                        <div class="card" v-if="false">
                            <div class="layout-title">教育经历
                                <span class="layout-more">编辑</span>
                            </div>
                            <div class="item">
                                <div class="flex-box">
                                    <div class="date">
                                        <b>开始时间</b> - <b>结束时间</b>
                                    </div>
                                    <div class="name">学校名称</div>
                                </div>
                                <div class="flex-box">
                                    <div class="left"><span>专业</span> | <span>学历</span></div>
                                </div>
                                <div class="content">
                                    大学之前的教育经历建议不写，尽量写与求职行业或者求职岗位相关的课程，有交流交换的经验可以在教育经历中展示。工作年限较多或成绩自认不够优异，则可以直接将教育背景清晰罗列后，重点丰富其他模块。成绩优异的话建议写上GPA及排名等信息，尽量简洁。
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="false">
                            <div class="layout-title">工作经历
                                <span class="layout-more">编辑</span>
                            </div>
                            <div class="item">
                                <div class="flex-box">
                                    <div class="date">
                                        <b>开始时间</b> - <b>结束时间</b>
                                    </div>
                                    <div class="name">学校名称</div>
                                </div>
                                <div class="flex-box">
                                    <div class="left"><span>职业名称</span> | <span>行业</span></div>
                                    <div class="right">月薪</div>
                                </div>
                                <div class="content">
                                    详细描述你的职责范围、工作内容和工作成果。最新的工作经验放在最前，描述尽量简洁，尽量写与职位相匹配的内容，将有助于HR第一时间发现你的亮点。如果是不知名企业，可以在工作内容的第一句话加上简短的公司或主要产品介绍，尽可能用数字说明成绩，突出分析能力、团队协作能力、解决问题的能力等工作上所需的专业素质。
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="false">
                            <div class="layout-title">项目经历
                                <span class="layout-more">编辑</span>
                            </div>
                            <div class="item">
                                <div class="flex-box">
                                    <div class="date">
                                        <b>开始时间</b> - <b>结束时间</b>
                                    </div>
                                    <div class="name">项目名称</div>
                                </div>
                                <div class="flex-box">
                                    <div class="left"><span>职位</span></div>
                                </div>
                                <div class="content">
                                    描述你参与过的项目及你在项目过程中所作的工作，内容简洁清晰，突出于求职岗位匹配的重点。具体可以从以下几个方面入手：1、项目内容 2、工作内容 3、项目成果。
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="layout-title">自我评价
                                <span class="layout-more" @click="dialogVisible4 = true">编辑</span>
                            </div>
                            <div class="item">
                                <div class="content">
                                    {{ pageData.intro ? pageData.intro : intro_tip }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

         <!--模态框  个人资料-->
        <el-dialog :visible.sync="dialogVisible" title="简历编辑" width="700px">
            <el-form :model="formData" ref="formData" :inline="true">
                <el-form-item label="姓名：" :label-width="formLabelWidth">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item  label="性别：" :label-width="formLabelWidth">
                    <el-radio-group v-model="formData.gender">
                        <el-radio value="1" label="1">男</el-radio>
                        <el-radio value="0" label="0">女</el-radio>
                    </el-radio-group>
                </el-form-item >
                <el-form-item label="联系方式：" :label-width="formLabelWidth">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱：" :label-width="formLabelWidth">
                    <el-input v-model="formData.email"></el-input>
                </el-form-item>
                <el-form-item label="出生日期：" :label-width="formLabelWidth">
                    <el-date-picker style="width: 202px;" v-model="formData.birthday" format="yyyy-MM-dd" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="民族：" :label-width="formLabelWidth">
                    <el-input v-model="formData.nation"></el-input>
                </el-form-item>
                <el-form-item label="最高学历：" :label-width="formLabelWidth">
                    <el-select v-model="formData.education" placeholder="请选择" style="width: 202px;">
                        <el-option label="请选择" :value="0"></el-option>
                        <el-option v-for="item in  $store.state.educationData" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="毕业状态：" :label-width="formLabelWidth">
                    <el-select v-model="formData.seniors_state" placeholder="请选择" style="width: 202px;">
                        <el-option label="请选择" :value="0"></el-option>
                        <el-option v-for="item in  $store.state.seniorState" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="期望薪资：" :label-width="formLabelWidth">
                    <el-input v-model="formData.salary_min" style="width: 140px;"></el-input>
                    <span style="margin: 0 10px;">至</span>
                    <el-input v-model="formData.salary_max" style="width: 140px;"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="operation('profile')">提交</el-button>
            </span>
        </el-dialog>
    
        <!--模态框  求职意向-->
        <el-dialog :visible.sync="dialogVisible2" title="求职意向" width="700px">
            <el-form :model="jobData" ref="jobData" :inline="false">
                <el-form-item  label="求职类型：" :label-width="formLabelWidth">
                    <el-radio-group v-model="jobData.to_type">{{ jobData.to_type }}
                        <el-radio value="1" label="1">全职</el-radio>
                        <el-radio value="2" label="2">兼职</el-radio>
                    </el-radio-group>
                </el-form-item >
                <el-form-item label="意向岗位：" :label-width="formLabelWidth">
                    <el-input v-model="jobData.job"></el-input>
                </el-form-item>
                <el-form-item label="意向城市：" :label-width="formLabelWidth">
                    <el-cascader :options="areaOptions" v-model="jobData.area_text" clearable style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="求职状态：" :label-width="formLabelWidth">
                    <el-select v-model="jobData.current_state" placeholder="请选择" style="width: 100%">
                        <el-option label="请选择" :value="0"></el-option>
                        <el-option v-for="item in  $store.state.jobSearchStatus" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取消</el-button>
                <el-button type="primary" @click="operation('job')">提交</el-button>
            </span>
        </el-dialog>

        <!--模态框  居住地-->
        <el-dialog :visible.sync="dialogVisible3" title="居住地" width="700px">
            <el-form :model="whereData" ref="whereData" :inline="false">
                <el-form-item label="地区：" :label-width="formLabelWidth">
                    <el-cascader :options="areaOptions" v-model="whereData.area_text" clearable style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="whereData.address" rows="3" resize="none" placeholder="xx街道xx小区"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取消</el-button>
                <el-button type="primary" @click="operation('where')">提交</el-button>
            </span>
        </el-dialog>

        <!--模态框  自我评价-->
        <el-dialog :visible.sync="dialogVisible4" title="自我评价" width="700px">
            <el-form :model="formData" ref="formData" :inline="false">
                <el-form-item label="自我评价：" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="introData.intro" rows="5" resize="none" :placeholder="intro_tip"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible4 = false">取消</el-button>
                <el-button type="primary" @click="operation('intro')">提交</el-button>
            </span>
        </el-dialog>

         <!--模态框  职业资格证书-->
         <el-dialog :visible.sync="dialogVisible5" :title="dialogTitle5" width="700px" class="prosImgs">
            <el-form :model="formData" ref="formData" :inline="false">
                <el-form-item label="证书名称：" :label-width="formLabelWidth">
                    <el-input v-model="imagesData.name"></el-input>
                </el-form-item>
                <el-form-item label="证书图片：" :label-width="formLabelWidth">
                    <el-upload
                    class="image-uploader"
                    :action="$store.state.Config.Api_Url+'/common/upload'"
                    :show-file-list="false"
                    :on-success="handleImagesSuccess"
                    :before-upload="beforeImagesUpload">
                    <img v-if="imagesData.fullurl" :src="imagesData.fullurl" class="image">
                    <i v-else class="el-icon-plus image-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible5 = false">取消</el-button>
                <el-button type="primary" @click="operation('img')">提交</el-button>
            </span>
        </el-dialog>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            dialogTitle5: '',
            imgDialogVisible: false,
            imgDialogTitle: '',
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            dialogVisible5: false,
            formLabelWidth: '100px',
            pageData: {
                avatar: '',
                name: '',
                phone: '',
                email: '',
                nation: '',
                gender: 1,
                birthday: 'null',
                education: '',
                seniors_state: '',
                salary: '',
                intro: '',
                to_type: '',
                job: '',
                current_state: '',
                area_text: '',
                to_type_text: '',
                education_text: '',
                seniors_state_text: '',
                current_state_text: '',
                pro_images: [],
                area_address: '',
            },
            formData: {
                operation: 'profile',
                name: '',
                phone: '',
                email: '',
                nation: '',
                gender: 1,
                birthday: 'null',
                education: '',
                seniors_state: '',
                salary: '',
                salary_min: '',
                salary_max: '',
            },
            jobData:{
                operation: 'job',
                to_type: '',
                area_text: '',
                job: '',
                current_state: '',
            },
            whereData:{
                operation: 'where',
                area_text: '',
                address: '',
            },
            imagesData:{
                id: '',
                name: '',
                image: '',
                fullurl: '',
            },
            introData:{
                operation: 'intro',
                intro: '',

            },
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            areaOptions:[],
            intro_tip: '篇幅不要太长，注意结合简历整体的美观度，内容中应总结经验和特长，突出符合求职岗位职位描述的特点，避免使用过多形容词。例：拥有良好的沟通和协调能力，善于应变，能够快速适应新环境，熟悉使用办公软件，对文件管理十分熟悉。',
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    mounted() {
        this.getInit();
    },
    methods: {
        //获取用户简历
        getInit(){
            let that = this;
            Api.User.userCV().then((res) => {
                if (res.data.code == 1) {
                    that.pageData = res.data.data;
                    //用户信息
                    that.formData.name = res.data.data.name;
                    that.formData.phone = res.data.data.phone;
                    that.formData.email = res.data.data.email;
                    that.formData.nation = res.data.data.nation;
                    that.formData.gender = res.data.data.gender + '';
                    that.formData.birthday = res.data.data.birthday;
                    that.formData.education = res.data.data.education;
                    that.formData.seniors_state = res.data.data.seniors_state;
                    that.formData.salary = res.data.data.salary;
                    that.formData.salary_min = res.data.data.salary_arr[0];
                    that.formData.salary_max = res.data.data.salary_arr[1];
                    //求职类型
                    that.jobData.job = res.data.data.job;
                    that.jobData.area_text = res.data.data.area_text;
                    that.jobData.to_type = res.data.data.to_type + '';
                    that.jobData.job = res.data.data.job;
                    that.jobData.area_text =  [res.data.data.to_province_id,res.data.data.to_city_id,res.data.data.to_area_id];
                    that.jobData.current_state = res.data.data.current_state;
                    //居住地
                    that.pageData.area_address = res.data.data.area_text + ' - ' + res.data.data.address ;
                    that.whereData.area_text = [res.data.data.province_id,res.data.data.city_id,res.data.data.area_id];
                    that.whereData.address = res.data.data.address;
                    //自我评价
                    that.formData.intro = res.data.data.intro;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //数据回填
        getPageInit(){
            let that = this;
            Api.User.userCV().then((res) => {
                if (res.data.code == 1) {
                    that.pageData.name = res.data.data.name;
                    that.pageData.phone = res.data.data.phone;
                    that.pageData.email = res.data.data.email;
                    that.pageData.gender_text = res.data.data.gender_text;
                    that.pageData.age = res.data.data.age;
                    that.pageData.salary = res.data.data.salary;
                    that.pageData.education_text = res.data.data.education_text;
                    that.pageData.seniors_state_text = res.data.data.seniors_state_text;
                    that.pageData.avatar = res.data.data.avatar;
                    that.pageData.job = res.data.data.job;
                    that.pageData.to_type_text = res.data.data.to_type_text;
                    that.pageData.area_text = res.data.data.area_text;
                    that.pageData.current_state_text = res.data.data.current_state_text;
                    that.pageData.area_address = res.data.data.area_text + ' - ' + res.data.data.address ;
                    that.pageData.intro = res.data.data.intro;
                }
            })
        },

        //修改简历基本信息
        operation(type){
            let that = this;
            switch(type){
                case 'profile':
                    Api.User.userCVupdate(that.formData).then((res) => {
                        if (res.data.code == 1) {
                            that.$message.success('简历更新成功');
                            that.dialogVisible = false;
                            that.getPageInit();
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    })
                    break;
                case 'job':
                    Api.User.userCVupdate(that.jobData).then((res) => {
                        if (res.data.code == 1) {
                            that.$message.success('简历更新成功');
                            that.dialogVisible2 = false;
                            that.getPageInit();
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    })
                    break;
                case 'where':
                    Api.User.userCVupdate(that.whereData).then((res) => {
                        if (res.data.code == 1) {
                            that.$message.success('简历更新成功');
                            that.dialogVisible3 = false;
                            that.getPageInit();
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    })
                    break;
                case 'intro':
                    Api.User.userCVupdate(that.introData).then((res) => {
                        if (res.data.code == 1) {
                            that.$message.success('简历更新成功');
                            that.dialogVisible4 = false;
                            that.getPageInit();
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    })
                    break;
                case 'img':
                    if(!that.imagesData.image || !that.imagesData.name){
                        that.$message.error('参数不正确');
                        return false;
                    }
                    Api.User.UserProEdit(that.imagesData).then((res) => {
                        if (res.data.code == 1) {
                            that.$message.success('证书上传成功！！');
                            that.imagesData.name = '';
                            that.imagesData.image = '';
                            that.imagesData.fullurl = '';
                            that.dialogVisible5 = false;
                            window.location.reload();
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    })
                    break;
            }
        },

        //求职意向
        dialogJob(){
            let that = this;
            that.getAreaOptions();
            that.dialogVisible2 = true;
        },

        //居住地
        setAddress(){
            let that = this;
            that.getAreaOptions();
            that.dialogVisible3 = true;
        },

        //获取地址地区
        getAreaOptions(){
            let that = this;
            Api.Config.AreaOptions().then((res) => {
                if(res.data.code == 1){
                    that.areaOptions = res.data.data;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },


        //头像上传
        handleAvatarSuccess(result, file) {
            let that = this;
            Api.User.UserCvAvatar({
                images: result.data.url
            }).then((res) => {
                if (res.data.code == 1) {
                    that.formData.avatar = result.data.fullurl;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        //职业资格证书上传成功后
        handleImagesSuccess(result, file) {
            let that = this;
            that.imagesData.image = result.data.url;
            that.imagesData.fullurl = result.data.fullurl;
        },
        beforeImagesUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 4;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 4MB!');
            }
            return isJPG && isLt2M;
        },

        //证书删除
        handleRemove(file) {
            let that = this;        
            Api.User.UserProImgDel({
                image: file.image
            }).then((res) => {
                if (res.data.code == 1) {
                    //刷新本页面
                    window.location.reload()
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //证书编辑
        handleUpdate(file){
            let that = this;        
            this.dialogVisible5 = true;
            this.dialogTitle5 = '编辑证书';
            this.imagesData.fullurl = file.fullurl;
            this.imagesData.name = file.name;
            this.imagesData.image = file.image;
            this.imagesData.id = file.id;
        },

        //证书放大
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.fullurl;
            this.imgDialogTitle = file.name;
            this.imgDialogVisible = true;
        },

    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    min-height: 400px;
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px;

    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    :deep(.avatar-uploader .el-upload:hover) {
        border-color: #409EFF;
    }
    :deep(.avatar-uploader-icon) {
        font-size: 28px;
        color: #8c939d;
        width: 125px;
        height: 155px;
        line-height: 178px;
        text-align: center;
        background: #e6e6e6 url(../../assets/icon/avatar.png) no-repeat center;
    }
    :deep(.avatar .el-icon-plus:before){
        content: '';
    }
    :deep(.avatar) {
        width: 178px;
        height: 178px;
        display: block;
    }

    .danger{
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        padding: 0 50px;
        margin-top: 20px;
        letter-spacing: 2px;

        .ico{
            margin-right: 20px;
        }

        .link{
            font-weight: bold;
            color: #D11A2D;
        }
    }

}

// 个人资料
.person{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content{
        flex: 1;

        .flex-box{
            display: flex;
            align-items: center;
        }

        .name{
            margin: 0;
            font-size: 26px;
            cursor: pointer;
        }
        .phone{
            cursor: pointer;
            background: url(../../assets/icon/mobile-ico.png) no-repeat center left;
            padding-left: 20px;
        }

        .el-item{
            cursor: pointer;
        }
    }

    .avatar{
        width: 120px;
        height: 155px;
        background: #e6e6e6;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            max-width: 100%;
        }
    }
}

.card{
    margin-top: 30px;

    .layout-title{
        font-size: 20px;
    }
    .content{
        line-height: 30px;
    }

    .flex-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
    }

    .item{
        padding-bottom: 20px;
        border-bottom: 1px dashed #eeeeee;
    }

    .item:last-child{
        border: none;
    }
}

.pro-images{

    .content{
        margin-top: 20px;
    }

    :deep(.el-upload-list--picture-card .el-upload-list__item-thumbnail){
        object-fit: cover!important;
    }
    
    :deep(.el-upload.el-upload--picture-card){
        display: none;
    }
    
}

.prosImgs{
    :deep(.image-uploader .el-upload){
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    :deep(.image-uploader .el-upload:hover){
        border-color: #409EFF;
    }
    :deep(.image-uploader-icon){
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    :deep(.image){
        width: 178px;
        height: 178px;
        display: block;
    }
}

</style>