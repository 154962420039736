<template>
  <transition>
      <div class="popup-container">
        <button class="close-button" @click="closePopup">&times;</button>
        <el-form :model="formData">
          <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>

          <el-form-item label="地区" prop="edu_areaName" :rules="{ required: true, message: '请选择地区', trigger: 'blur' }">
            <el-input v-model="formData.edu_areaName" placeholder="如：xx省xxx市xxx区/县"></el-input>
          </el-form-item>



          <el-form-item label="学校" prop="edu_schoolID" :rules="{ required: true, message: '请选择学校', trigger: ['blur', 'change'] }">
            <el-select v-model="formData.edu_schoolID" placeholder="请选择">
              <el-option
                  v-for="item in options.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="性别" prop="sex" :rules="{ required: true, message: '请输入性别', trigger: ['blur', 'change'] }">
            <el-input v-model="formData.sex" placeholder="如：男"></el-input>
          </el-form-item>

          <el-form-item label="年龄" prop="age" :rules="{ required: true, message: '请输入您的年龄', trigger: ['blur', 'change'] }">
            <el-input v-model="formData.age" placeholder="如：18"></el-input>
          </el-form-item>

          <el-form-item label="民族" prop="nation" :rules="{ required: true, message: '请输入您的民族', trigger: ['blur', 'change'] }">
            <el-input v-model="formData.nation" placeholder="如：汉"></el-input>
          </el-form-item>

          <el-form-item label="手机号" prop="phone" :rules="{ required: true, pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }">
            <el-input v-model="formData.phone" placeholder="如：13812345678"></el-input>
          </el-form-item>
          <el-form-item label="可用的优惠卷">
            <div class="coupon-list" v-if="flage">
              <div v-for="(couponse, index) in couponse.data.filter(coupon => coupon.preferential.specifictid === this.formData.edu_schoolID)" :key="index" class="coupon-card">
                <div class="logo-container">
                  <!-- 这里可以放置您的logo图片，使用img标签或者背景图片方式 -->
                  <img src="@/assets/images/logo1.jpg" alt="Your Logo" class="logo-image" />
                </div>
                <div class="coupon-header">
                  <h3>{{ couponse.preferential.name}}</h3>
                  <span class="coupon-expire">有效期至 {{ couponse.preferential.outtime}}</span>
                </div>
                <div class="coupon-body">
                  <p>{{couponse.preferential.threshold}}</p>
                </div>
                <div class="coupon-footer">
                  <span class="coupon-discount">{{ couponse.preferential.reduction }}% OFF</span>
                  <el-badge :value="`${couponse.countCa}`" :max="99" class="item">
                    <button v-if="!formData.preferentials.split(',').includes(couponse.preferentialid.toString())"  @click.prevent="subsss(couponse)">使用该优惠卷</button>
                    <button v-if="formData.preferentials.split(',').includes(couponse.preferentialid.toString())" @click.prevent="exitOdEr(couponse)">取消使用</button>
                  </el-badge>
                </div>
              </div>
              <br>
              <el-pagination
                  @size-change="handleSizeChanges"
                  @current-change="handleCurrentChanges"
                  :current-page="couponse.current_page"
                  :page-sizes="[2, 5, 10, 20]"
                  :page-size="couponse.per_page"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="couponse.total">
              </el-pagination>
            </div>
          </el-form-item>


          <el-form-item>
            <el-button type="primary" @click="submitForm">提交报名</el-button>
          </el-form-item>
          <el-form/>
        </el-form>

      </div>

  </transition>
</template>

<script>
import Api from "@/Api";
export default {
  name:"signup",
  data() {
    return {
      activeIndex: '1',
      formData: {
        preferentials:""
      },
      formRules: {},
      options:{},
      couponse: {
        per_page: 2,
        current_page: 1,
        data: []
      },
      flage:false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.getschoo();
    this.getByuserprte();
  },
  methods: {
    subsss(coupon) {
      if (this.formData.preferentials==""){
        this.formData.preferentials=coupon.preferentialid;
      }else {
        this.formData.preferentials+=","+coupon.preferentialid;
      }
      console.info(this.formData.preferentials);
    },
    exitOdEr(coupon) {
      // 将之前的preferentials字符串转换为数组，便于操作
      let preferentialIds = this.formData.preferentials ? this.formData.preferentials.split(',') : [];

      // 检查当前coupon的id是否已经在数组中
      const index = preferentialIds.indexOf(coupon.preferentialid.toString());

      // 如果ID存在，则移除
      if (index !== -1) {
        // 从数组中移除ID
        preferentialIds.splice(index, 1);

        // 移除后处理可能产生的连续逗号或开头逗号
        // 这里利用正则表达式去除连续逗号和可能的开头逗号
        preferentialIds = preferentialIds.map(id => id.trim()).filter(Boolean); // 确保没有空字符串
        this.formData.preferentials = preferentialIds.join(',');
      }
      // 将处理后的数组重新转换为字符串，更新到formData中
      this.formData.preferentials = preferentialIds.join(',');
    },
    submitForm(){
      this.formData.state=3;
      this.formData.createID=this.$store.state.user_info.id;
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      this.formData.createtiem=formattedDate;
      Api.Applicants.InsterEntity(
          this.formData
      ).then((res) => {
        if (res.data.msg=="添加申请人成功"){
          this.$message({
            message: '成功录入,请等待通知',
            type: 'success'
          });
        }else {
         
          this.$message({
            message: '请检查数据填写是否正确,或是联系管理员',
            type: 'warning'
          });
        }
      })
    },
    handleSelect(key, keyPath) {
      this.formData={};
    },

    closePopup() {
      this.$emit('close'); // 使用 .sync 修饰符更新父组件的 visible 属性
    },
    getschoo(){
      Api.School.getSchoolInit().then((res) => {
        if (res.data.code == 1) {
          this.options = res.data.data.dataList;
        }
      })
    },
    handleSizeChanges(val){
      this.couponse.per_page=val;
      this.getByuserprte();
    },
    handleCurrentChanges(val){
      this.couponse.current_page=val;
      this.getByuserprte();
    },
    // Inside your methods:
    getByuserprte() {
      let that = this;
      Api.Userprefe.Realpolicy({
        userName: that.$store.state.user_info.nickname,
        per_page: that.couponse.per_page,
        current_page: that.couponse.current_page,
      }).then(res => {
        // Directly assign new data to trigger reactivity
        that.couponse = {
          ...res.data.data, // Spread operator to create a new object reference
          per_page: that.couponse.per_page,
          current_page: that.couponse.current_page,
        };

        let promises = [];
        for (let i = 0; i < that.couponse.data.length; i++) {
          // Temporarily nullify preferential to indicate loading
          that.$set(that.couponse.data, i, {
            ...that.couponse.data[i],
            preferential: null
          }); // Use $set for Vue to track changes in array elements

          promises.push(
              Api.Preferential.Realpolicy({ id: that.couponse.data[i].preferentialid }).then(res => {
                // Ensure Vue knows about the nested property change
                that.$set(that.couponse.data[i], 'preferential', res.data);
              })
          );
        }

        Promise.all(promises).then(() => {
          // Force a re-render by toggling a data property
          that.flage = !that.flage;
        });
      });
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  z-index: 1000;
}

.popup-content {
  /* 添加必要的样式，例如字体、颜色、间距等 */
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}



.coupon-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.coupon-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.coupon-card:hover {
  transform: translateY(-5px);
}

.coupon-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.coupon-expire {
  float: right;
  color: #999;
}

.coupon-body {
  padding: 20px;
}

.coupon-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
}

.coupon-discount {
  color: #d4235c;
  font-weight: bold;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.container {
  position: relative; /* 使内部的定位元素（如logo）能够相对于此容器定位 */
}

.logo-container {
  position: absolute;
  top: 10px; /* 调整logo与顶部的距离 */
  right: 20px; /* 调整logo与右侧的距离 */
  z-index: 1; /* 确保logo在列表卡片之上 */
}

.logo-image {
  width: 50px; /* 根据实际logo大小调整 */
  height: auto;
  border-radius:50%
}
</style>