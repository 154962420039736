<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main">
                        <el-form :model="formData" :rules="ruleForm" ref="formData" label-width="150px">
                            <el-form-item label="已绑定的手机号：">
                                {{oldmobile ? oldmobile : $store.state.user_info.mobile}}
                            </el-form-item>
                            <el-form-item label="手机号：" prop="mobile">
                                <el-input v-model="formData.mobile" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码：" prop="code">
                                <el-input v-model="formData.code" style="width: 300px;">
                                    <el-button slot="append" :disabled="isDisabled" @click="sendSms()">{{tip}}</el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="button" class="btn-normal" @click="onSubmit">确认修改</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            tip: '获取验证码',
            second: 30,
            isDisabled: false,
            timer: null,
            oldmobile: '',
            formData: {
                mobile: '',
                code: '',
            },
            ruleForm: {
                mobile: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请输入验证码',
                    trigger: 'blur'
                }],
            },
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    methods: {
        //确定
        onSubmit(){
            let that = this;
            if(!that.formData.mobile || !that.formData.code){
                that.$message.error('请输入内容再提交...');
                return false;
            }

            Api.User.BindMobile(that.formData).then((res) => {
                if (res.data.code == 1) {
                    that.$message.success('绑定成功');
                    that.oldmobile = that.formData.mobile;
                    that.formData.mobile = '';
                    that.formData.code = '';
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //获取验证码
        sendSms() {
            let that = this;
            if(!that.formData.mobile){
                that.$message.error('手机号不能为空');
                return false;
            }
            Api.Config.SendSms({
                mobile: that.formData.mobile,
                event: 'changemobile',
            }).then((res) => {
                if(res.data.code == 1){
                    //按钮倒计时
                    that.btnTimmer();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //倒计时
        btnTimmer(){
            this.timer = setInterval(() => {
                if(this.second == 0){
                    clearInterval(this.timer);
                    this.tip = '获取验证码';
                    this.isDisabled = false;
                    this.second = 30;
                }else{
                    this.second -- ;
                    this.tip = this.second + 's重新获取';
                    this.isDisabled = true;
                }
            }, 1000);
        },

    },

}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 20px;
}

</style>