<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main">
                        <div class="list-box">
                            <el-table :data="dataList.data" style="width: 100%">
                                <el-table-column prop="createtime_text" label="交易时间" min-width="220"></el-table-column>
                                <el-table-column prop="score" label="变动积分" min-width="100"></el-table-column>
                                <el-table-column prop="before" label="变更前" min-width="100"></el-table-column>
                                <el-table-column prop="after" label="变动后" min-width="100"></el-table-column>
                                <el-table-column prop="memo" label="备注" min-width="180"></el-table-column>
                            </el-table>
                        </div>

                        <div class="page-block">
                            <el-pagination background @current-change="handleCurrentChange" :page-size="dataList.per_page" layout="prev, pager, next, jumper" :total="dataList.total">
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            page: 1,
            dataList: [],
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    methods: {
        getDataLists() {
            let that = this;
            Api.User.GetScoreLog({
                page: that.page,
            }).then((res) => {
                if(res.data.code == 1){
                    that.dataList = res.data.data;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //切换分页
        handleCurrentChange(val) {
            this.page = val;
            this.getDataLists();
        }

    },

    created() {
        this.getDataLists();
    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 20px;
    position: relative;
    
    .page-block{
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }

    :deep(.el-pager li:not(.disabled).active){
        background: #D11A2D!important;
    }

    :deep(.el-pager li:not(.active):hover){
        color: #D11A2D!important;
    }
}

</style>