<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="footer">
        <div class="text-center">
            <div class="state">版权所有 未经允许不得转载</div>
            <div class="miit">
                <a href="https://beian.miit.gov.cn" target="_blank" class="miit1">豫ICP备2021035209号-1</a>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502002811" target="_blank" class="miit2">服务许可号：豫ICP备2021035209号-1</a>
            </div>
            <div class="txt">© Copyright 2021-现在   河南勋鸿文化传播有限公司 版权所有 zhongguojiaoyunet.com</div>
<!--            <div class="txt">推荐浏览器：最新版的Edge、国产双核浏览器、FireFox、Opera、Chrome浏览器。</div>-->
<!--            <div class="txt">为了防范电信网络诈骗，如网民接到962110电话，请立即接听</div>-->
        </div>
    </div>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
}
</script>
 
<style scoped lang="less">
    .footer{
        height: 120px;
        background: #292a32;
        padding: 30px 0;

        .copy{
            font-size: 14px;
            line-height: 40px;
            color: #ffffff;
        }

        .miit{
            width: 100%;
        }

        .miit1{
            font-size: 13px;
            text-decoration: none;
            color: #939393;
        }

        .miit2{
            margin-left: 20px;
            font-size: 13px;
            text-decoration: none;
            color: #939393;
            background: url(../assets/icon/icp-logo.png) no-repeat left center;
            padding-left: 22px;
        }

        .state{
            font-size: 14px;
            line-height: 40px;
            color: #ffffff;
        }

        .txt{
            font-size: 12px;
            color: #939393;
            line-height: 30px;
        }
    }
</style>