<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main">
                        <div class="invite-code">
                            <div>我的邀请码：<b>{{invite_code}}</b></div>
                            <div>邀请总数：<b>{{dataList.total}}</b></div>
                        </div>
                        <div class="list-box">
                            <el-table :data="dataList.data" style="width: 100%">
                                <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
                                <el-table-column label="头像" min-width="80">
                                    <template slot-scope="scope">
                                        <div>
                                            <img class="avatar-img" v-if="scope.row.avatar" :src="scope.row.avatar">
                                                <span v-if="!scope.row.avatar">-</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="nickname" label="昵称" min-width="120"></el-table-column>
                                <el-table-column prop="mobile" label="手机号" min-width="160"></el-table-column>
                                <el-table-column prop="join_time_text" label="加入时间" min-width="220"></el-table-column>
                            </el-table>
                        </div>

                        <div class="page-block">
                            <el-pagination background @current-change="handleCurrentChange" :page-size="dataList.per_page" layout="prev, pager, next, jumper" :total="dataList.total">
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            page: 1,
            invite_code:'',
            dataList: [],
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    created() {
        this.getUserInvite();
    },
    methods: {
         getUserInvite() {
            let that = this;
            Api.User.UserInviteData({
                page: that.page,
            }).then((res) => {
                if(res.data.code == 1){
                    that.invite_code = res.data.data.invite_code;
                    that.dataList = res.data.data.invite_data;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        //切换分页
        handleCurrentChange(val) {
            this.page = val;
            this.getUserInvite();
        }

    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 20px;

    .invite-code{
        padding: 0 20px 20px 30px;
        border-bottom: 1px dashed #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;

        b{
            font-size: 22px;
            color: #D11A2D;
        }
    }

    .avatar-img {
        width: 26px;
        height: 26px;
        border: 1px solid #E5E5E5;
    }

    .page-block{
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }

    :deep(.el-pager li:not(.disabled).active){
        background: #D11A2D!important;
    }

    :deep(.el-pager li:not(.active):hover){
        color: #D11A2D!important;
    }
}

</style>