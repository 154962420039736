<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main" v-if="$store.state.userTrueAuth">
                        <div class="danger">
                            <img class="ico" src="../../assets/svg/smile.svg" alt="">
                            恭喜，您已完成<span>实名认证</span>！！
                        </div>
                        <el-form  label-width="150px">
                            <el-form-item label="姓名：" prop="truename">
                                {{  $store.state.user_info.truename }}
                            </el-form-item>
                            <el-form-item label="身份证号：" prop="identify_num">
                                {{  $store.state.user_info.identify_num }}
                            </el-form-item>
                        </el-form>
                    </div> 

                    <div class="main" v-if="!$store.state.userTrueAuth">
                        <div class="danger">
                            <img class="ico" src="../../assets/svg/danger-ico.svg" alt="">
                            为了您的账号权益，请及时进行<span>实名认证</span>！！
                        </div>
                        <el-form :model="formData" :rules="ruleForm" ref="formData" label-width="150px">
                            <el-form-item label="姓名：" prop="truename">
                                <el-input v-model="formData.truename" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号：" prop="identify_num">
                                <el-input v-model="formData.identify_num" style="width: 400px;"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="button" class="btn-normal" @click="onSubmit">提交认证</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            authStatus: false,
            formData: {
                truename: '',
                identify_num: '',
            },
            ruleForm: {
                truename: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                identify_num: [{
                    required: true,
                    message: '请输入身份证号',
                    trigger: 'blur'
                }],
            },
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    mounted(){
    },
    methods: {
        //确定
        onSubmit(){
            let that = this;
            if(!that.formData.truename || !that.formData.identify_num){
                that.$message.error('请输入内容再提交...');
                return false;
            }

            //提交
            Api.User.TureName(that.formData).then((res) => {
                if (res.data.code == 1) {
                    that.$message.success(res.data.msg);
                    location.reload();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    min-height: 400px;
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 20px;

    .danger{
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        padding: 0 50px;
        margin-top: 20px;
        letter-spacing: 2px;

        .ico{
            margin-right: 20px;
        }

        span{
            font-weight: bold;
            color: #D11A2D;
        }
    }

}

</style>