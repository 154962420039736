<template>
    <div class="banner">
        <div class="content">
            <div class="layout flex-box">
                <img class="avatar" :src="UserInfo.avatar" alt="">
                <div class="title">
                    <h4 class="nickname">{{UserInfo.nickname}} <span v-if="userAuth" class="auth">已实名</span> </h4>
                    <p class="username">
                        <span>用户名：{{UserInfo.username}} </span>
                        <span class="ml-4">积分：{{UserInfo.score}}</span>
                    </p>
                </div>
                <div class="inviter">
                    <p>地址：{{UserInfo.province_name}}-{{UserInfo.city_name}}</p>
                    <p>注册时间：{{UserInfo.join_date}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/Api.js";
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            userAuth: false,
            UserInfo:{
                id:'',
                mobile: '',
                avatar:'',
                username:'',
                nickname:'',
                score:'',
                province_name: '',
                province_id: '',
                city_name: '',
                city_id: '',
                createtime: '',
                truename: '',
                identify_num: '',
            }
        }
    },
    methods: {
        ...mapActions(['setUserInfo']),
        getUserInfo() {
            let that = this;
            Api.User.UserInfo().then((res) => {
                if(res.data.code == 1){
                    console.info(res.data.data);
                    that.UserInfo = res.data.data;
                    that.$store.commit("setUserInfo", res.data.data);
                    if(that.UserInfo.truename && that.UserInfo.identify_num){
                        that.userAuth = true;
                    }
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
    },
    created() {
        this.getUserInfo();
    },
    computed:{
        ...mapState(['userInfo']),
    },
}
</script>

<style scoped lang="less">
.banner{
    height: 190px;
    background: url(../../assets/images/person-bg.png) no-repeat center;
    background-size: cover;
    position: relative;

    .content{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0 , 0.4);
    }

    .flex-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .avatar{
        display: block;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 4px rgba(0, 0, 0, .25) solid;
        margin-top: 40px;
    }

    .title{
        flex: 1;
        margin-left: 30px;
        padding-top: 20px;

        .nickname{
            color: #ffffff;
            font-size: 22px;
            margin: 0;
        }

        .auth{
            font-size: 12px;
            padding: 2px 5px;
            font-weight: normal;
            color: #ffd500;
            border-radius: 3px;
            border: 1px solid #ffd500;
            margin-left: 10px;
            cursor: pointer;
        }

        .username{
            margin: 20px 0 0;
            color: #ffffff;
        }
    }

    .inviter{
        margin-top: 20px;
        color: #ffffff;

        p{
            margin: 10px 0;
        }
    }
}
</style>