<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <div class="banner">
            <div class="box">
                <h2>欢迎反馈，您的意见我们非常重视！</h2>
                <router-link class="btn" to="">常见问题</router-link>
            </div>
        </div>
        <div class="layout content">
            <el-form :model="formData" :rules="ruleForm" ref="ruleForm" label-width="220px" style="padding-right: 200px;">
                <el-form-item label="意见建议：" prop="content">
                    <el-input type="textarea" v-model="formData.content" rows="8" resize="none"></el-input>
                </el-form-item>
                <el-form-item label="联系方式：" prop="phone">
                    <el-input v-model="formData.phone"  maxlength="11"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="button" class="btn-normal" @click="onSubmit">立即提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </main>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
            formData: {
                content: '',
                images: '',
                phone: '',
            },
            ruleForm: {
                content: [{
                    required: true,
                    message: '请输入内容',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    message: '请输入联系方式',
                    trigger: 'blur'
                }],
            },
        }
    },
    methods: {
        //确定
        onSubmit(){
            let that = this;
            Api.Config.Feedback(that.formData).then((res) => {
                if (res.data.code == 1) {
                    that.$message.success('反馈成功，我们会尽快处理！');
                    that.formData.content = '';
                    that.formData.images = '';
                    that.formData.phone = '';
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

    },
    mounted() {
        // this.getDetail();
    },
    watch: {
        '$route' () {
            this.getDetail();
        }
    }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  min-height: calc(100vh - 300px);

  .banner{
    height: 200px;
    background: url(../assets/images/feedback-banner.png) no-repeat center top #f2f2fe;

    .box{
        width: 800px;
        margin: 0 auto;
        padding: 66px 0 0 14px;
    }

    h2{
        font-size: 32px;
        font-weight: normal;
        margin: 0;
    }

    .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 140px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        color: #D11A2D;
        font-size: 18px;
        font-weight: bold;
        background: #ffffff;
        border-radius: 16px;
        text-decoration: none;
    }

    .btn:hover{
        background: #fafafa;
    }
  }

  .content{
    padding: 50px 0;
  }
}

</style>