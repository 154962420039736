<template>
  <transition name="fade">
    <div v-if="visible" class="popup-container">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">参加项目申请</el-menu-item>
        <el-menu-item index="2">职业资格证书申请</el-menu-item>
      </el-menu>
      <!-- 项目申请信息录入 -->
      <el-form v-if="occupationallevel==true" ref="registrationForm" :model="formData" :rules="formRules" label-position="left" label-width="100px" @submit.prevent="submitForm1">
        <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="性别">
          <el-radio-group v-model="formData.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="card pro-images">
          <div class="layout-title">项目照片
            <el-upload
                class="avatar-uploader"
                :action="$store.state.Config.Api_Url+'/common/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="formData.imgs" :src="`http://oss.zhongguojiaoyunet.com/${formData.imgs}`" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>

        <el-form-item label="项目名称" prop="point" :rules="{ required: true, message: '请选择项目', trigger: ['blur', 'change'] }">
          <el-select v-model="formData.point" placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <div class="card pro-images">
          <div class="layout-title">学历资格证书
            <el-upload
                class="avatar-uploader"
                :action="$store.state.Config.Api_Url+'/common/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeAvatarUpload1">
              <img v-if="formData.qualificationimgs" :src="`http://oss.zhongguojiaoyunet.com/${formData.qualificationimgs}`" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>

        <el-form-item label="当前职业" prop="occupation" :rules="{ required: true, message: '请输入当前职业', trigger: 'blur' }">
          <el-input v-model="formData.occupation"></el-input>
        </el-form-item>

        <el-form-item label="友情链接" prop="Friendship" :rules="{ required: true, message: '请输入友情链接', trigger: 'blur' }">
          <el-input v-model="formData.Friendship"></el-input>
        </el-form-item>

        <el-form-item label="链接介绍" prop="Introduction" :rules="{ required: true, message: '请输入链接介绍', trigger: 'blur' }">
          <el-input v-model="formData.Introduction"></el-input>
        </el-form-item>

        <el-form-item label="详细地区" prop="areaID" :rules="{ required: true, message: '请输入地区名称', trigger: 'blur' }">
          <el-input v-model="formData.areaID"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm1">提交报名</el-button>
        </el-form-item>
      </el-form>

      <!-- 职业证书信息录入 -->
      <el-form v-if="projectcheckin==true" ref="registrationForm" :model="formData" :rules="formRules" label-position="left" label-width="100px" @submit.prevent="submitForm2">
        <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <el-form-item label="性别">
          <el-radio-group v-model="formData.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="card pro-images">
          <div class="layout-title">证件照
            <el-upload
                class="avatar-uploader"
                :action="$store.state.Config.Api_Url+'/common/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="formData.imgs" :src="`http://oss.zhongguojiaoyunet.com/${formData.imgs}`" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>

        <el-form-item label="证书名称" prop="point" :rules="{ required: true, message: '请选择证书', trigger: ['blur', 'change'] }">
          <el-select v-model="formData.point" placeholder="请选择">
            <el-option
                v-for="item in options1"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <div class="card pro-images">
          <div class="layout-title">学历资格证书
            <el-upload
                class="avatar-uploader"
                :action="$store.state.Config.Api_Url+'/common/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeAvatarUpload1">
              <img v-if="formData.qualificationimgs" :src="`http://oss.zhongguojiaoyunet.com/${formData.qualificationimgs}`" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>

        <el-form-item label="当前职业" prop="occupation" :rules="{ required: true, message: '请输入当前职业', trigger: 'blur' }">
          <el-input v-model="formData.occupation"></el-input>
        </el-form-item>

        <el-form-item label="友情链接" prop="Friendship" :rules="{ required: true, message: '请输入友情链接', trigger: 'blur' }">
          <el-input v-model="formData.Friendship"></el-input>
        </el-form-item>

        <el-form-item label="链接介绍" prop="Introduction" :rules="{ required: true, message: '请输入链接介绍', trigger: 'blur' }">
          <el-input v-model="formData.Introduction"></el-input>
        </el-form-item>

        <el-form-item label="详细地区" prop="areaID" :rules="{ required: true, message: '请输入地区名称', trigger: 'blur' }">
          <el-input v-model="formData.areaID"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm">提交报名</el-button>
        </el-form-item>
      </el-form>
      <div class="popup-content">
      </div>
      <!-- 关闭按钮 -->
      <button @click="closePopup" class="close-button">&times;</button>
      <!--模态框  职业资格证书-->
    </div>


  </transition>
</template>

<script>
import Api from "@/Api";

export default {
  name:"signup",
  data() {
    return {
      activeIndex: '1',
      projectcheckin:true,
      occupationallevel:false,
      formData: {
        imgs:'',
        qualificationimgs:''
      },
      formRules: {},
      options1:null,
      options2:null,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleAvatarSuccess(res, file) {
      this.formData.imgs = res.data.url;
      if (this.formData.imgs!=null){
        this.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success'
        });
      }else {
        this.$notify.error({
          title: '错误',
          message: '上传失败请联系管理员或者查看上传规格'
        });
      }
      console.info(this.formData.imgs);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess1(res, file) {
      this.formData.qualificationimgs = res.data.url;
      if (this.formData.qualificationimgs!=null){
        this.$notify({
          title: '成功',
          message: '上传成功',
          type: 'success'
        });
      }else {
        this.$notify.error({
          title: '错误',
          message: '上传失败请联系管理员或者查看上传规格'
        });
      }
    },
    beforeAvatarUpload1(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    submitForm(){
      this.formData.type=1;
      this.insetre();
    },
    insetre(){
      this.formData.createID=this.$store.state.user_info.id;
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      this.formData.createtiem=formattedDate;
      Api.Applicationdemopro.add(this.formData).then(res=>{
        if (res.data.msg=="添加申请人成功"){
          this.$message({
            message: '成功录入,请等待通知',
            type: 'success'
          });
        }else {
          this.$message({
            message: '请检查数据填写是否正确,或是联系管理员',
            type: 'warning'
          });
        }
      })
    },
    handleSelect(key, keyPath) {
      if (key==1){
        this.occupationallevel=true;
        this.projectcheckin=false;
      }else if (key==2){
        this.occupationallevel=false;
        this.projectcheckin=true;
      }
      this.formData={};
    },
    closePopup() {
      this.$emit('close');
    },
    getall(){
      Api.Projectcheckin.ALLInit({}).then(res1=>{
        this.options1=res1.data.data.data;
      })
      Api.Occupationallevel.ALLInit({}).then(res2=>{
        this.options2=res2.data.data.data;
      })
    },
  },
  mounted() {
    this.getall();
  },
  created() {

  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  z-index: 1000;
}

.popup-content {
  /* 添加必要的样式，例如字体、颜色、间距等 */
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>