<template>
  <div>
      <span id='rt'>
    点击展示详细的地址：
    <span id='rl'></span>
  </span>
    <div id="mapaddrs" style="width: 100%; height: 35rem;"></div>
  </div>

</template>
<script>
import {loadBMap} from "@/map";
const $ = require('jquery');
export default {
  name: 'Selectaddress',
  data(){
    return{
    }
  },
  created() {
    loadBMap("huBFyJxnpJHjggDlzWveyQqbpR8b5t6V").then(()=>{
        this.initMap();
    })
  },
  methods: {
    initMap() {
      const map = new BMap.Map('mapaddrs'); // 创建Map实例
      // 创建一个定位对象
      var geolocation = new BMap.Geolocation();

      // 开始定位
      geolocation.getCurrentPosition(function(r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          // 获取当前位置的经纬度
          var mk = new BMap.Marker(r.point);
          map.addOverlay(mk); //标出当前位置
          map.panTo(r.point); //移动地图中心点到当前位置
          console.info(mk);
          let op = {
            lng:mk.point.lng,
            lat:mk.point.lat,
          };
          let maplist=[];
          maplist.push(op);
          for (let i = 0; i < maplist.length; i++) {
            let point=new BMap.Point(maplist[i].lng,maplist[i].lat);
            map.centerAndZoom(point, 18); // 初始化地图,设置中心点坐标和地图级别
          }
          map.enableScrollWheelZoom(true);      //开启鼠标滚轮缩放


          var geoc = new BMap.Geocoder();
          map.addEventListener('click', function(e){

            var pt = e.Dg;
            console.log(pt);
            geoc.getLocation(pt, function(rs){
              var addComp = rs.addressComponents;
              var addr=addComp.province  + addComp.city  + addComp.district  + addComp.street  + addComp.streetNumber;
              if (confirm("是否进行导航")) {
                // const startLng = op.lng;
                // const startLat = op.lat;
                const endLng = pt.lng;
                const endLat = pt.lat;
                let baiduUrl = `marker?location=${endLat},${endLng}&title=${addr}&content=${addr}`;
                let url = `https://api.map.baidu.com/${baiduUrl}&output=html&src=webapp.baidu.openAPIdemo`;
                window.open(url, '_blank'); // 打开新窗口进行导航
              }
              $('#rl').text(addComp.province + ", " + addComp.city + ", " + addComp.district + ", " + addComp.street + ", " + addComp.streetNumber);
            })
          })
        } else {
          console.log('定位失败：' + this.getStatus());
        }
      }, {enableHighAccuracy: true})

    }
}
}
</script>

<style>
/* 样式按需添加 */
#mapaddrs{
  width: 650px;
  height: 350px;
  margin-top: 10px;
  border: 1px solid red;
}
#rt{

  z-index: 99;
}
</style>