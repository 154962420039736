<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <div class="layout">
      <el-row>
        <!-- 详情 -->
        <el-col :span="18">
          <div class="content-box">
            <h2 class="title">{{ item.detail.title }}</h2>
            <div class="auth-box">
              <div class="username">
                <el-avatar size="small" :src="item.detail.admin.avatar"></el-avatar>
                <span class="ml-2">{{item.detail.admin.nickname }}</span>
              </div>
              <div class="date">{{ item.date }}</div>
              <div class="views">{{ item.views }} 阅读</div>
              <div class="source" v-if="item.detail.source">转载于：<span>{{ detailData.Name }}</span></div>
            </div>
            <div class="intro-box" v-if="item.detail.excerpt">
              {{ item.excerpt }}
            </div>
            <div class="cont" v-html="detailData.Content "></div>
          </div>
        </el-col>

        <!-- 推荐 -->
        <el-col :span="6">
          <div class="list-box">
            <div class="title">相关推荐</div>
            <div class="content">
              <div class="item" v-for="item in item.lists">
                <router-link class="txt" :to="'/article?id='+item.id">{{ item.title }}</router-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </main>
</template>
<script>
import Api from "@/Api.js";
export default {
  data() {
    return {
      item: '',
      recommendList: [],
      detailData: {

      }
    }
  },
  methods: {
    //数据填充
    getDetail(){
      let that = this;
      if (that.item.detail.cid==8){
        Api.Trainingemploymentprojects.init({
          id:that.item.detail.source
        }).then(res=>{
          that.detailData=res.data.data.data[0];
        })
      }else  if (that.item.detail.cid==7){
        Api.Oneemploymentprojects.init({
          id:that.item.detail.source
        }).then(res=>{
          that.detailData=res.data.data.data[0];
        })
      }else  if (that.item.detail.cid==9){
        Api.Collegeadmissionprojects.init({
          id:that.item.detail.source
        }).then(res=>{
          that.detailData=res.data.data.data[0];
        })
      }

    },

  },
  created() {
    this.item = this.$route.query.item;
    console.info(this.item);
    this.getDetail();
  },
  mounted() {

  }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  padding: 20px 0;
  background: #f7f8fa;
}

.content-box{
  background: #ffffff;
  border: 1px solid #f1f1f1;
  min-height: calc(100vh - 300px);
  position: relative;
  padding: 0 20px;

  .title{
    font-weight: normal;
  }

  .auth-box{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
  }

  .username{
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  .date,.views,.source{
    padding: 0 15px;
    border-left: 1px solid #cccccc;
  }

  .intro-box{
    margin: 20px 0;
    border-radius: 10px;
    padding: 40px 30px;
    background: #f7f8fa;
    position: relative;
    line-height: 28px;
    font-size: 16px;
    word-break: break-all;
  }

  .intro-box::before{
    width: 24px;
    height: 18px;
    display: block;
    content: '';
    background: transparent url(../../assets/icon/intro-yinhao.png) no-repeat top left;
    background-size: 24px;
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .intro-box::after{
    width: 24px;
    height: 18px;
    display: block;
    content: '';
    background: transparent url(../../assets/icon/intro-yinhao.png) no-repeat bottom left;
    background-size: 24px;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }

}

.list-box{
  width: 100%;
  margin-left: 20px;
  background: #ffffff;

  .title{
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eeeeee;
  }

  .content{
    padding: 10px 0;
  }

  .item{
    display: block;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .txt{
    margin-left: 5px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
  }

  .item:hover .txt{
    color: #D11A2D;
  }

  .item::before{
    content: '';
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #D11A2D;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item:nth-child(1)::before{
    content: '1'!important;
  }
  .item:nth-child(2)::before{
    content: '2'!important;
  }
  .item:nth-child(3)::before{
    content: '3'!important;
  }
  .item:nth-child(4)::before{
    content: '4'!important;
  }
  .item:nth-child(5)::before{
    content: '5'!important;
  }
  .item:nth-child(6)::before{
    content: '6'!important;
  }
  .item:nth-child(7)::before{
    content: '7'!important;
  }
  .item:nth-child(8)::before{
    content: '8'!important;
  }
  .item:nth-child(9)::before{
    content: '9'!important;
  }
  .item:nth-child(10)::before{
    content: '10'!important;
  }

}
</style>