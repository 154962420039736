<template>
  <div v-if="showModal" class="modal-mask">
    <div class="modal-body">
      <slot name="body">
        <video id="backgroundVideo" src="https://admin.zhongguojiaoyunet.com/uploads/20240710/b204daa8958566f9e3ae0c3aadb74771.mp4" autoplay loop muted playsinline></video>
        <button @click="closeModal" id="closeButton">关闭</button>
      </slot>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Api from "@/Api";

export default {
  data() {
    return {
      showModal: false,
      ctx: null,
      fireworks: [],
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },

  },
  mounted() {
    Api.Newuser.getUserById({
      id: this.$store.state.user_info.id
    }).then(res => {
      if (res.data.prevtime != null) {
        this.showModal = true;

      }
    })
  }
}
</script>

<style scoped>
#welcomeModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

canvas {
  display: block;
  margin: auto;
  background: black;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#fullScreenVideoContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}


#closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5); /* 半透明白色 */
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 99; /* 确保视频在其他内容之下 */
}

#closeButton:hover {
  color: white; /* 鼠标悬停时变为不透明 */
}

#backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持视频宽高比并填充整个容器 */
  z-index: 1; /* 确保视频在其他内容之下 */
}
</style>