<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <div class="layout">
      <el-row>
        <el-col :span="18">
          <div class="content-box">
            <h2 class="title">{{ detailData.title }}</h2>
            <div class="auth-box">
              <div class="username">
                <span class="ml-2">{{ detailData.bianjimingzi }}</span>
              </div>
              <div class="date">{{ detailData.registrationtime }}</div>
              <div class="source" v-if="detailData.source">来源：<span>{{ detailData.source }}</span></div>
            </div>
            <div id="contents"></div>
<!--            <div v-html="detailData.content"></div>-->
            <div class="cont1" v-for="(item, index) in this.recommendList" :key="index">
              <h2>{{ item.Name }}</h2>
              <img :src="`http://oss.zhongguojiaoyunet.com`+item.path">
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
  </main>
</template>
<script>
import Api from "@/Api.js";
const $ = require('jquery');
export default {
  data() {
    return {
      id: '',
      recommendList: [],
      detailData: null,
    }
  },
  mounted() {
  },
  methods: {
    //数据填充
    getDetail(){
      let that = this;
      Api.Studentbrochure.getByid({
        id:that.id
      }).then(res=>{
        console.info(res.data.data[0]);
        that.detailData=res.data.data[0];
        that.convertToHtml(that.detailData.content);
        console.info(that.detailData.content);
        $(document).ready(function() {
          let inHTMLsDiv = $('#contents'); // 使用jQuery选择器，'#'表示ID'
          if (inHTMLsDiv.length) { // jQuery对象存在至少一个匹配的元素
            inHTMLsDiv.text(that.detailData.content); // 使用.text()设置内容
          } else {
            console.error('Element with ID "inHTMLContent" not found.'); // 错误处理
          }
        });
      });
      Api.Studentbrochureappendix.getByid({
        id:that.id
      }).then(res=>{
        that.recommendList=res.data.data;
      });
    },
      convertToHtml(text) {
      // 使用正则表达式替换换行符为段落标签
      // \n\n 匹配连续两个换行符，表示新段落的开始
      // g 是全局搜索的标志，用于替换所有匹配项
      let htmlContent = text.replace(/\n\n/g, '');
      this.detailData.content = htmlContent.trim();
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  }, computed: {

},
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  padding: 20px 0;
  background: #f7f8fa;
}
.cont1{
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  min-height: 100vh; /* 确保页面高度足够时也能居中 */
  text-align: center; /* 保证文本也居中 */
}
.content-box{
  background: #ffffff;
  border: 1px solid #f1f1f1;
  min-height: calc(100vh - 300px);
  position: relative;
  padding: 0 20px;

  .title{
    font-weight: normal;
  }

  .auth-box{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
  }

  .date,.views,.source{
    padding: 0 15px;
    border-left: 1px solid #cccccc;
  }

  .intro-box{
    margin: 20px 0;
    border-radius: 10px;
    padding: 40px 30px;
    background: #f7f8fa;
    position: relative;
    line-height: 28px;
    font-size: 16px;
    word-break: break-all;
  }

  .intro-box::before{
    width: 24px;
    height: 18px;
    display: block;
    content: '';
    background: transparent url(../../assets/icon/intro-yinhao.png) no-repeat top left;
    background-size: 24px;
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .intro-box::after{
    width: 24px;
    height: 18px;
    display: block;
    content: '';
    background: transparent url(../../assets/icon/intro-yinhao.png) no-repeat bottom left;
    background-size: 24px;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }

}

.list-box{
  width: 100%;
  margin-left: 20px;
  background: #ffffff;

  .title{
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eeeeee;
  }

  .content{
    padding: 10px 0;
  }

  .item{
    display: block;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .txt{
    margin-left: 5px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
  }

  .item:hover .txt{
    color: #D11A2D;
  }

  .item::before{
    content: '';
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #D11A2D;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .item:nth-child(1)::before{
    content: '1'!important;
  }
  .item:nth-child(2)::before{
    content: '2'!important;
  }
  .item:nth-child(3)::before{
    content: '3'!important;
  }
  .item:nth-child(4)::before{
    content: '4'!important;
  }
  .item:nth-child(5)::before{
    content: '5'!important;
  }
  .item:nth-child(6)::before{
    content: '6'!important;
  }
  .item:nth-child(7)::before{
    content: '7'!important;
  }
  .item:nth-child(8)::before{
    content: '8'!important;
  }
  .item:nth-child(9)::before{
    content: '9'!important;
  }
  .item:nth-child(10)::before{
    content: '10'!important;
  }

}
</style>