<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
       <div class="layout">
        <el-row>
            <!-- 菜单 -->
            <el-col :span="6">
                <div class="menu-box">
                    <h2 class="title">综合服务</h2>
                    <router-link :class="'item '+ (!cid ? 'active': '')"  :to="'/articles?keywords='+keywords">
                        <span @click="onCategoryClick(null,null)">全部</span>
                    </router-link>
                    <router-link v-for="cate in cateList" :to="'/articles?cid='+cate.id+'&keywords='+keywords" :class="'item '+ (cid == cate.id ? 'active': '')">
                        <span @click="onCategoryClick(cate.id,cate.name)">{{cate.name}}</span>
                    </router-link>
                </div>
            </el-col>

            <!-- 列表 -->
            <el-col :span="18">
                <div class="content-box">
                    <div class="list-title">
                        位置：
                        <router-link class="item" to="/">首页</router-link>
                        >
                        <router-link class="item active" to="/articles">{{this.cname}}</router-link>
                    </div>
                    <div class="list-box">
                        <div class="list" v-for="item in articleData.data" v-if="articleData.total>0">
                              <a class="cont" @click="godetail(item.cid,item)" >
                              {{item.title}}</a>
                              <span class="date">{{item.date}}</span>
                        </div>
                        <div class="null-box" v-if="articleData.total == 0">
                            <img src="../../assets/svg/null.svg" class="null-img" alt="null">
                            <p>暂无数据...</p>
                        </div>
                    </div>

                    <div class="page-block">
                        <el-pagination background @current-change="handleCurrentChange" :page-size="articleData.per_page" layout="prev, pager, next, jumper" :total="articleData.total">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
       </div>
    </main>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
            page: 1,
            cid: 0,
            keywords: '',
            cateList: [],
            articleData: {
                data: [],
                total: 1,
                per_page: 20,
            },
          cname:"全部",
        }
    },
    methods: {
      godetail(id,item){
        Api.Article.GetArticlesDetail({
          id:item.id,
        }).then(res=>{
          item=res.data.data;
          if (item.detail.cid=="7"){
            this.$router.push({ path: '/Projects', query: { item: item } });
          }else if (item.detail.cid=="8"){
            this.$router.push({ path: '/Projects', query: { item: item } });
          }else if (item.detail.cid=="9"){
            this.$router.push({ path: '/Projects', query: { item: item } });
          } else {
            this.$router.push({ path: '/article', query: { id: id } });
          }
        })
      },
      onCategoryClick(cid,name) {
        if (name==null){
          this.cname="全部";
        }else {
          this.cname=name;
        }

      },
        //数据填充
        getDataListInit(){
            let that = this;
            that.cid = this.$route.query.cid;
            that.keywords = !this.$route.query.keywords ? '' : this.$route.query.keywords ;
            Api.Article.GetArticlesInit({
                cid: that.cid,
                page: that.page,
                keywords: that.keywords,
            }).then((res) => {
                if (res.data.code == 1) {
                    that.cateList = res.data.data.category;
                    that.articleData = res.data.data.articles;
                  
                }
            })
        },

        //切换分页
        handleCurrentChange(val) {
            let that = this;
            that.page = val;
            Api.Article.GetArticles({
                cid: that.cid,
                page: that.page,
                keywords: that.keywords,
            }).then((res) => {
                if (res.data.code == 1) {
                    that.articleData = res.data.data;
                }
            })
        }
    },
    mounted() {
        this.getDataListInit();
    },
    watch: {
        '$route' () {
            this.getDataListInit();
        }
    }
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  padding: 20px 0;
  background: #f7f8fa;
}

.menu-box{
    width: 100%;
    padding-right: 20px;

    .title{
        width: 100%;
        border-radius: 5px;
        color: #ffffff;
        padding: 15px;
        text-align: center;
        margin: 0;
        box-sizing: border-box;
        background: #fd5732;
        font-size: 20;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .item{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 20px;
        font-size: 16px;
        text-decoration: none;
        background: #ffffff;
        border: 1px solid #f1f1f1;
        position: relative;
    }

    span{
        flex: 1;
        color: #333333;
    }

    .item:before{
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: #D11A2D;
        margin-right: 10px;
    }

    .item:after{
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(../../assets/icon/menu-right.png) no-repeat center;
        background-size: cover;
    }

    .item.active span,
    .item:hover span{
        color: #D11A2D;
    }

    .item.active:after,
    .item:hover:after{
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(../../assets/icon/menu-right-hover.png) no-repeat center;
        background-size: cover;
    }
}

.content-box{
    background: #ffffff;
    border: 1px solid #f1f1f1;
    min-height: calc(100vh - 300px);
    position: relative;

    .list-title{
        padding: 12px 20px;
        border-bottom: 1px solid #dddddd;
        background: #F7F7F7;
    }

    .list-title .item{
        margin: 0 10px;
        color: #666666;
        font-size: 16px;
        text-decoration: none;
    }

    .list-title .item.active{
        color: #D11A2D;
    }

    .list-box{
        width: 100%;
        padding: 10px;
        margin-bottom: 55px;
    }

    .null-box{
        text-align: center;
        padding: 50px 0;
    }

    .null-img{
        width: 400px;
    }
   
    .list{
        display: flex;
        align-items: center;
        padding: 10px 20px;
    }

    .list:hover{
        background: #fafafa;
    }

    .cont{
        flex: 1;
        font-size: 14px;
        color: #333333;
        text-decoration: none;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .date{
        font-size: 14px;
        color: #333333;
        min-width: 110px;
        text-align: right;
    }

    .list:hover .cont{
        color: #D11A2D;
    }

    // 分页
    .page-block{
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }

    :deep(.el-pager li:not(.disabled).active){
        background: #D11A2D!important;
    }

    :deep(.el-pager li:not(.active):hover){
        color: #D11A2D!important;
    }
}
</style>