<template>
  <div class="page-container">
    <div class="chat-container">
      <div class="chat-messages" ref="messagesContainer">
        <div v-for="(message, index) in messages" :key="index" :class="{ 'self': message.sender === 'user', 'agent': message.sender === 'agent' }">
          {{ message.text }}
        </div>
      </div>
      <div class="chat-input">
        <el-input :rows="2" type="textarea" v-model="newMessage" @keyup.enter="sendMessage" placeholder="请输入消息..."></el-input>
              <el-button type="primary" icon="el-icon-eleme" @click="sendMessage">发送</el-button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      messages: [], // 存储聊天消息
      newMessage: '', // 当前输入的消息
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        // 添加用户消息到数组
        this.messages.push({ sender: 'user', text: this.newMessage });
        this.newMessage = ''; // 清空输入框

        // 假设这里调用API发送消息到服务器，并等待服务器返回客服回复
        // 实际应用中，这将涉及到WebSocket或轮询等技术
        // this.sendToServer(this.newMessage);
      }
    },

    // 假设的函数用于与服务器通讯
    // sendToServer(message) {
    //   // 使用axios或fetch等发送消息到后端
    // }
  },
  mounted() {
    // 这里可以添加监听器来接收服务器推送的消息并更新messages数组
    // 例如，使用WebSocket时的连接和消息接收逻辑
    // this.setupWebSocket();
  },
  beforeDestroy() {
    // 清理，比如关闭WebSocket连接
    // this.closeWebSocket();
  },
};
</script>

<style scoped>

/* 页面主体容器，确保聊天界面不会影响到其他内容的布局 */
.page-container {
  position: relative;
  min-height: 100vh; /* 至少占据一屏高度，确保有足够的空间 */
  padding-bottom: 100px; /* 为页脚预留空间，根据实际页脚高度调整 */
}
.chat-container {
  width: 65%;
  max-width:65%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: absolute; /* 或 fixed，根据需要调整 */
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.chat-messages {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
}

.chat-messages div {
  margin-bottom: 10px;
}

.self {
  text-align: right;
  background-color: #DCF8C6;
  padding: 5px 10px;
  border-radius: 5px;
}

.agent {
  text-align: left;
  background-color: #E1EAFB;
  padding: 5px 10px;
  border-radius: 5px;
}

.chat-input {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

input[type="text"] {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}
</style>