<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <div class="layout header">
            <a href="/" class="logo">
                <img src="../assets/icon/logo1.png" class="logo-img" alt="">
            </a>
            <div class="notice">
                <router-link to="/login">登录</router-link>
                <span> | </span>
                <router-link to="/register">注册</router-link>
            </div>
        </div>
        <main class="page">
            <div class="layout">
                <h2 class="title">{{ data.name }}</h2>
                <p class="date">更新日期：{{ data.date }}</p>
                <div class="content" v-html="data.content"></div>
            </div>
        </main>
    </div>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
            key: 'users',
            data: {},
        }
    },
    mounted() {
        //获取数据
        this.key = this.$route.query.key
        this.GetData();
    },
    methods: {
        // 获取数据
        GetData() {
            let that = this;
            Api.Config.Agreement({
                    key: that.key,
            }).then((res) => {
                if(res.data.code == 1){
                    that.data = res.data.data;
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },
    },
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/
.header {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-box{
        display: flex;
        align-items: center;
    }
    
    .logo-img{
        width: auto;
        height: 76px;
    }

    .notice{
        color: #333333;

        a{
            color: #D11A2D;
        }
    }

}

.page{
    min-height: calc(100vh - 300px);
    background: #f7f8fa;

    .title{
        text-align: center;
        padding: 20px;
    }

    .date{
        text-align: right;
    }

    .content{
        padding-bottom: 30px;
    }
}
</style>