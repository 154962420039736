<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
      <meta name="keywords" content="教育,生活,政策,人生规划,院校信息">
        <!--轮播图-->
        <div class="layout clearfix" v-if="BannerList!=[]" style="margin-bottom: 20px;" v-loading="loading">
            <div class="banner-box fl">
                <el-carousel :interval="5000" >
                    <el-carousel-item v-for="(item, index) in BannerList" :key="index">
                        <!-- 轮播图片 -->
                        <el-image :src="item.image" style="width: 1188px;height: 425px"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!-- 左右两列资讯 -->
        <div class="layout flexs" v-if="cateArticles!=null">
        <div class="flex-item" v-for="list in cateArticles">
          <div class="flex-title">
            {{list.name}}
            <span @click="getbycateArticles" class="flex-more">查看更多>></span>
          </div>
          <ul class="content">
            <li class="list" v-for="(item, index) in list.articles" :key="index">
              <!-- 使用三元表达式来决定跳转的URL -->
              <router-link :to="list.name === '政策文件' ? '/policydocument?id=' + item.ID  : '/article?id=' + item.id " :title="item.title">{{item.title}}</router-link>
              <span>{{item.date}}</span>
            </li>
          </ul>
        </div>
      </div>

        <!-- 左右第二列两列资讯 -->
        <div class="layout flexs" v-if="proDemo!=null">
          <div class="flex-item" v-for="list in proDemo">
            <div class="flex-title">
              {{list.name}}
              <span @click="getbyproDemo" class="flex-more">查看更多>></span>
            </div>
            <ul class="content" v-if="shouldDisplayContent(list.name)"> <!-- 在这里添加v-if判断 -->
              <li class="list" v-for="(item, index) in list.articles" :key="index">
                <router-link :to="'employmentproject?id='+item.id" :title="item.Name">{{item.Name}}</router-link>
                <span>{{item.yourdates}}</span>
              </li>
            </ul>
            <ul class="content" v-if="shouldDisplayContent1(list.name)"> <!-- 在这里添加v-if判断 -->
              <li class="list" v-for="(item, index) in list.articles" :key="index">
                <router-link :to="'Training?id='+item.id" :title="item.Name">{{item.Name}}</router-link>
                <span>{{item.yourdates}}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 左右第三列两列资讯 -->
        <div class="layout flexs" v-if="proDemos!=null">
          <div class="flex-item" v-for="list in proDemos">
            <div class="flex-title">
              {{list.name}}
              <span @click="getbyproDemos" class="flex-more">查看更多>></span>
            </div>
            <ul class="content">
              <li class="list" v-for="(item, index) in list.articles" :key="index">
                <router-link :to="'article?id='+item.id" :title="item.Name">{{item.Name}}</router-link>
                <span>{{item.yourdates}}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 左右第四列两列资讯 -->
        <div class="layout flexs" v-if="proDemos2!=null">
          <div class="flex-item" v-for="list in proDemos2">
            <div class="flex-title">
              {{list.name}}
              <span @click="getbyproDemos2" class="flex-more">查看更多>></span>
            </div>
            <ul class="content">
              <li class="list" v-for="(item, index) in list.articles" :key="index">
                <router-link :to="'businessinde?id='+item.id" :title="item.link_text">{{item.link_text}}</router-link>
                <span>{{item.createtime}}</span>
              </li>
            </ul>
          </div>
        </div>

        <!--立即报名-->
        <div class="layout" v-if="srcimgs1!=null">
          <a class="wsc-poster" style="width: 1188px;height: 222px">
            <el-carousel :interval="5000" arrow="always"  class="custom-carousel">
              <el-carousel-item v-for="(item, index)  in this.srcimgs1" :key="index">
                <el-image :src="item.image"  :alt="item.alts"></el-image>
              </el-carousel-item>
            </el-carousel>
          </a>
        </div>

        <!--教育优势-->
        <div class="wsc-f7f8fa">
            <div class="layout edu-attrs">
                <div class="layout-title">教育优势</div>
                <div class="content">
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">在线题库</h4>
                            <p class="desc">大量知识点供你选择</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-1.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">面试课程</h4>
                            <p class="desc">一对一辅导改变弱项</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-2.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">考点直击</h4>
                            <p class="desc">指导历年重点考题</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-3.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">免费试听</h4>
                            <p class="desc">了解授课教师情况</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-4.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">智能教学</h4>
                            <p class="desc">学情分析找到薄弱环节</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-5.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">随时随地</h4>
                            <p class="desc">任意时间，任意地点</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-6.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">时事政治</h4>
                            <p class="desc">热门讯息以及推荐</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-7.png" alt="">
                    </div>
                    <div class="item slate5-shadow">
                        <div class="cont">
                            <h4 class="title">考试快讯</h4>
                            <p class="desc">最新考试、招聘等消息</p>
                        </div>
                        <img class="ico" src="../assets/edu-ico/edu-ico-8.png" alt="">
                    </div>
                </div> 
            </div>
        </div>
       
        <!--师资团队-->
<!--        <div class="layout teachers">-->
<!--            <div class="layout-title">-->
<!--                师资团队-->
<!--                <a href="" class="layout-more">查看更多>></a>-->
<!--            </div>-->
<!--            <ul class="content">-->
<!--                <li class="item" v-for="(item, index) in TeacherList" :key="index">-->
<!--                    <div class="info">-->
<!--                        <img class="avatar" :src="item.avatar" alt="">-->
<!--                        <p class="title">{{item.learn}}</p>-->
<!--                        <p class="name">{{item.name}}</p>-->
<!--                    </div>-->
<!--                    <div class="intro">{{item.intro}}</div>-->
<!--                    <div class="text-center">-->
<!--                        <a href="" class="link">课程详情</a>-->
<!--                    </div> -->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->

        <!--好课推荐-->
        <div class="wsc-f7f8fa">
            <div class="layout videos">
                <div class="layout-title">
                    好课推荐
                    <a href="" class="layout-more">查看更多>></a>
                </div>
                <div class="content">
                <a href="" class="item slate5-shadow" v-for="(item, index) in VideoList" :key="index">
                    <div class="replay-box">
                        <img class="img" :src="item.img" alt="">
                        <div class="replay-cover"></div>
                    </div> 
                    <div class="title">{{item.title}}</div>
                </a>
            </div>
            </div>
        </div>

        <!-- 友情链接 -->
        <div class="layout friends">
            <div class="layout-title">
                常用链接
            </div>
            <div class="content">
                <a :href="item.url" target="_blank" class="item slate5-shadow" v-for="(item, index) in FriendsList" :key="index">
                    <img class="logo" :src="item.logo" alt="">
                    <span class="txt">{{item.name}}</span>
                </a>
            </div>
        </div>
    </main>
</template>
<script type="text/javascript">
import Api from "@/Api.js";
import {loadBMap} from "@/map";
export default {
    data() {
        return {
          srcimgs1:[
            {image:"https://admin.zhongguojiaoyunet.com/uploads/20240704/7ddcad5337137c0cafa71ad427980cf9.jpg"},
            {image:"https://admin.zhongguojiaoyunet.com/uploads/20240704/3ffb4ab95a818a07a78f39849cfc0f5d.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/4cff7335ea361c1941c20d9183143ba9.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/e7e67c2deee1b0996d88f1cbd80a97db.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/5ddb27931efba66e5a7937f44242ed15.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/e2f06f2038b495ec373756736971baf9.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/fd3725fba07194b725eda21794664929.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/47e9c1a140d176a05e9e5c99db547b8d.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/df5833691ac492a76cb859d5edbecde7.jpg"},
            {image: "https://admin.zhongguojiaoyunet.com/uploads/20240704/0ac3941ec6d3613b408bbfa2ae1f1753.jpg"},
          ],
          drawers:null,
          loading:true,
          isFromOtherPage:false,
          directions:null,
          projectcheckin:[],
          proDemo:[{id: 2, name: "就业资讯", pid: 0, status: "normal", hot: 1, createtime: 1702020824, updatetime: 1702285583,articles:[]},
            {id: 1, name: "培训资讯", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484,articles:[]}
          ],
          proDemos:[{id: 2, name: "创业孵化", pid: 0, status: "normal", hot: 1, createtime: 1702020824, updatetime: 1702285583,articles:[]},
            {id: 1, name: "人才智库", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484,articles:[]}
          ],
          proDemos2:[{id: 1, name: "技能竞赛", pid: 0, status: "normal", hot: 1, createtime: 1702020675, updatetime: 1702285484,articles:[]}],

          // <!-- 当前定位运营中心培训计划 -->
          tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }],
          Areas:{
            id: 410105, label: "金水区", level: 3, name: "金水区", pid: 410100, value: 410105,
          },
          LocationPCity: "正在进行定位",
          drawer: false, //从下方弹出
          direction: 'btt',  //方向
          customAddress: '',
            wx_code: '',
            UserInfo:{},
            HomeList: [],
            //轮播图
            BannerList: [],
            //友情链接
            FriendsList: [],
            //最新快讯
            newArticles: [],
            cateArticles: [],
            TeacherList: [],
            VideoList: [
                {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大',
                }, {
                    id: 1,
                    img:'https://pic.huke88.com/video/cover/2022-07-21/D3463880-4513-CD20-F290-D020E7B9279C.jpg!/fwfh/580x360/quality/100/unsharp/true/format/jpg',
                    title: 'Excel基本操作究竟有多强大Excel基本操作究竟有多强大',
                }
            ],
            message: [],
            recommendedVideo: [],
          Nationalpolicy:[],
          Temploymentindividual:[],
          Ttrainingandemployment:[],
          Skillscompetition:[],
          Article:[]
        }
    },
  computed: {
    myState: {
      get() {
        return this.$store.getters.myState;
      }
    }
  },
  watch: {
    myState(newValue, oldValue) {
      //目前逻辑为国内的信息
      this.renew(newValue[1],newValue[2],newValue[4]);
    }
  },
    methods: {
      shouldDisplayContent(name) {
        return name === '就业资讯';
      },
      shouldDisplayContent1(name){
        return name === '培训资讯';
      },
      renew(newValue,newcity,judge){
        Api.Config.GetInit({
          areaid:newValue,
          city:newcity,
          judge:judge,
        }).then((res) => {
          this.UserInfo = res.data.data.userinfo;
          this.TeacherList = res.data.data.teacher;
          this.BannerList = res.data.data.banner;
          this.FriendsList = res.data.data.friends;
          this.newArticles = res.data.data.new_articles;
          this.cateArticles = res.data.data.cate_articles;
          Api.Nationalpolicy.Realpolicy({
            provinceareaids:this.$store.state.LocationPCity[1],
            areaid:this.$store.state.LocationPCity[2],
          }).then((res)=>{
            this.Nationalpolicy=res.data.Ndata.data;
            this.cateArticles[0].name = "政策文件";
            this.cateArticles[0].articles = this.Nationalpolicy;
            this.Article=res.data.Adata.data;
            this.cateArticles[1].articles = this.Article;
            this.Temploymentindividual=res.data.T1data.data;
            this.proDemo[0].articles=this.Temploymentindividual;
            this.Ttrainingandemployment=res.data.T2data.data;
            this.proDemo[1].articles=this.Ttrainingandemployment;
            this.Skillscompetition=res.data.Sdata.data;
            this.proDemos2[0].articles=this.Skillscompetition;
            this.loading=false;
          })
        })
      },
      getbycateArticles(){
        let that=this;
        Api.Nationalpolicy.Realpolicy1({
          areaid:this.$store.state.LocationPCity[1],
        }).then((res)=>{
          that.Nationalpolicy=res.data.data.data;
          that.cateArticles[0].name = "政策文件";
          that.cateArticles[0].articles = that.Nationalpolicy;
        })
        Api.Article.Realpolicy1({
          areaid:this.$store.state.LocationPCity[1],
        }).then((res)=>{
          this.Article=res.data.data.data;
          this.cateArticles[1].articles = this.Article;
        })
      },
      getbyproDemo(){
        let that=this;
        Api.Temploymentindividual.Realpolicy1({
          areaid:this.$store.state.LocationPCity[1],
        }).then((res)=>{
          that.Temploymentindividual=res.data.data.data;
          that.proDemo[0].articles=that.Temploymentindividual;
        })
        Api.Ttrainingandemployment.Realpolicy1({
          areaid:this.$store.state.LocationPCity[1],
        }).then((res)=>{
          that.Ttrainingandemployment=res.data.data.data;
          that.proDemo[1].articles=that.Ttrainingandemployment;
        })
      },
      getbyproDemos2(){
        let that=this;
        Api.Skillscompetition.Realpolicy1({
          areaid:this.$store.state.LocationPCity[1],
        }).then((res)=>{
          that.Skillscompetition=res.data.data.data;
          that.proDemos2[0].articles=that.Skillscompetition;
        })
      },
      getbyproDemos(){
        let that=this;
      },
      handleClose(done) {
        this.$confirm('确认返回主界面吗？')
            .then(_ => {
              done();
            })
            .catch(_ => {});
      },
      open(){
          this.$message("选择您要报名的院校");
        this.$router.push("/schools");
      },
    },
    mounted() {
      if (this.$store.state.LocationPCity[0]!=null){
        this.renew(this.$store.state.LocationPCity[1],this.$store.state.LocationPCity[2],this.$store.state.LocationPCity[4]);
      }
    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/

#fiex_riget{
  position: fixed; /* 使元素脱离文档流并相对于视口定位 */
  top: 12rem; /* 距离底部的距离 */
  left: 0.5%; /* 距离右侧的距离 */
  z-index: 1; /* 设置较高的z-index，确保元素浮于其他内容之上 */
  transition: opacity 0.3s ease; /* 可选，添加平滑的透明度过渡效果 */
}
#container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "微软雅黑";
}

.cy1{
  text-align: center;
}
.page{
  padding: 10px 0;
}

.banner-box{
    width: 1188px;
    height: 425px;
}

:deep(.el-carousel__container){
    height: 425px!important;
}
  // }

// 资讯
.articles{
    padding: 35px 0;

    .content{
        border: 1px solid #CDCDCD;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 0;
        background: #ffffff;
    }

    .list{
        box-sizing: border-box;
        width: 50%;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        span{
            font-size: 14px;
            min-width: 60px;
            text-align: right;
        }

        a{
            text-decoration: none;
            font-size: 14px;
            color: #333333;
            flex: 1;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }

    .list:hover span,
    .list:hover a{
        color: #D11A2D;
    }
}

// 立即报名
.wsc-poster{
    margin: 30px 0 0;
    display: block;
    cursor: pointer;

    .poster-img{
        width: 100%;
        height: auto;
    }

    /deep/ ::-webkit-scrollbar {

      display: none;

    }
}

// 教育优势
.edu-attrs{
    padding: 35px 0;

    .content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .item{
        width: 282px;
        background: #ffffff;
        padding: 20px;
        border-radius: 5px;
        height: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .cont{
        width: 142px;
        text-align: left;

       .title{
            font-size: 20px;
            line-height: 40px;
            margin: 0;
       }

       .desc{
            margin: 0;
            height: 50px;
            font-size: 16px;
            color: #999999;
       }
    }

    .ico{
        margin-left: 10px;
        width: 90px;
        height: 90px;
    }
}

// 师资团队
.teachers{
    padding: 30px 0;

    .content{
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .item{
        width: 342px;
        border-radius: 5px;
        background: #252930;
        border: 1px solid #CDCDCD;
        padding: 30px 20px;
    }

    .info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .avatar{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        margin-bottom: 10px;
    }

    .title{
        color: #999999;
        margin: 0;
        line-height: 30px;
    }

    .name{
        color: #ffffff;
        margin: 0;
        line-height: 30px;
    }

    .intro{
        height: 90px;
        color: #999999;
        font-size: 14px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }

    .link{
        margin-top: 15px;
        display: inline-block;
        width: 120px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 5px;
        background: #191c21;
        color: #dddddd;
        transition: all 0.2s;
        text-decoration: none;
    }

    .link:hover{
        color: #191c21;
        background: #dddddd;
    }
}

// 好课推荐
.videos{
    padding: 30px 0;

    .content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .item{
        width: 282px;
        display: block;
        border-radius: 5px;
        overflow: hidden;
        background: #ffffff;
        text-decoration: none;
        margin-bottom: 20px;
        border: 1px solid #eeeeee;
    }

    .replay-box{
        width: 282px;
        height: 170px;
        position: relative;

        .replay-cover{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.35);
            opacity: 0;
            transition: all .25s;
        }

        .replay-cover:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 55%;
            margin: -30px 0 0 -30px;
            width: 60px;
            height: 60px;
            transition: all .25s;
            background-image: url(../assets/icon/replay.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
        }
    }

    .item:hover .replay-cover {
        opacity: 1
    }
    
    .item:hover .replay-cover:before {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    .img{
        width: 282px;
        height: 170px;
        object-fit: cover;
    }
    
    .title{
        padding: 0 10px;
        text-decoration: none;
        color: #333333;
        font-size: 16px;
        line-height: 40px;
        height: 50px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .title:hover{
        color: #D11A2D;
    }
}

// 左右两列资讯
.flexs{
    display: flex;
    align-items: start;
    justify-content: space-between;
    table-layout: fixed;
    padding: 40px 0;

    .flex-item{
        display: table-cell;
        width: 585px;
        border-radius: 5px;
        border: 1px solid #eeeeee;
    }

    .flex-title{
        padding: 15px 20px;
        font-size: 20px;
        color: #D11A2D;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7f8fa;
    }

    .flex-more{
        color: #999999;
        font-size: 14px;
        text-decoration: none;
        font-weight: normal;
    }

    .flex-more:hover{
        color: #666666;
    }

    .content{
        margin: 0;
        padding: 10px 0;
        list-style-type: none;
        justify-content: space-between;
        box-sizing: border-box;
        background: #ffffff;
    }

    .list{
        box-sizing: border-box;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        span{
            font-size: 14px;
            min-width: 60px;
            text-align: right;
        }

        a{
            text-decoration: none;
            font-size: 14px;
            color: #333333;
            flex: 1;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }

    .list:hover span,
    .list:hover a{
        color: #D11A2D;
    }
}

// 友情链接
.friends{
    padding: 30px 0;

    .content{
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
    }

    .item{
        box-sizing: border-box;
        width: 25%;
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .logo{
        width: 60px;
        margin-right: 20px;
    }

    .txt{
        font-size: 16px;
        color: #333;
    }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.custom-carousel {
  height: 128px;
  overflow: hidden; /* 防止图片溢出 */
}

/* 确保图片铺满轮播框但不拉伸 */
.custom-carousel .el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片宽高比并填充容器 */
}

@media screen and (max-width: 600px) {
  /* 手机端样式 */
  .banner-box {
    width: 100%;
    height: auto;
  }
  :deep(.el-carousel__container) {
    height: auto !important;
  }
  .el-image {
    width: 100% !important;
    height: auto !important;
  }
  .flexs {
    flex-direction: column;
  }
  .flex-item {
    width: 100%;
  }
  .edu-attrs .item {
    width: 100%;
  }
  .item {
    flex-direction: column;
  }
  .cont, .ico {
    width: 100%;
    text-align: center;
  }
  .teachers .item {
    width: 100%;
  }
  .videos .item {
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  /* 平板端样式 */
  .banner-box {
    width: 100%;
    height: auto;
  }
  :deep(.el-carousel__container) {
    height: auto !important;
  }
  .el-image {
    width: 100% !important;
    height: auto !important;
  }
  .flexs {
    flex-direction: column;
  }
  .flex-item {
    width: 100%;
  }
  .edu-attrs .item {
    width: 50%;
  }
  .teachers .item {
    width: 50%;
  }
  .videos .item {
    width: 50%;
  }
}
</style>