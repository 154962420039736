<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <UserBanner></UserBanner>

        <div class="layout cont-box">
            <el-row style="width: 100%;">
                 <!-- 菜单 -->
                 <userNavs></userNavs>

                <!-- 内容 -->
                <el-col :span="19">
                    <div class="main">
                        <el-form :model="formData" label-width="100px">
                            <el-form-item label="昵称：" prop="phone">
                                <el-input v-model="formData.nickname" style="width: 300px;"></el-input>
                            </el-form-item>
                            <el-form-item label="头像" prop="avatar">
                                <el-upload
                                    class="avatar-uploader" :action="$store.state.Config.Api_Url+'/common/upload'"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="formData.avatar" :src="avatar" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="button" class="btn-normal" @click="onSubmit">确认修改</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
        </div>
    </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
    data() {
        return {
            id: '',
            formData: {
                nickname: '',
                avatar: '',
            },
            avatar:'',
        }
    },
    components: {
        UserBanner,
        userNavs,
    },
    methods: {
        //确定
        onSubmit(){
            let that = this;
            Api.User.UserProfile(that.formData).then((res) => {
                if (res.data.code == 1) {
                    that.$message.success('更新成功');
                    location.reload();
                }else{
                    that.$message.error(res.data.msg);
                }
            })
        },

        handleAvatarSuccess(res, file) {
            this.avatar = res.data.fullurl;
            this.formData.avatar = res.data.url;
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }

    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    // padding: 20px 0;
    background: #f7f8fa;
    min-height: calc(100vh - 300px);
}

.cont-box{
    padding: 50px 0;
    display: flex;
}

.main{
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 30px 20px;


    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    :deep(.avatar-uploader .el-upload:hover) {
        border-color: #409EFF;
    }
    :deep(.avatar-uploader-icon) {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    :deep(.avatar) {
        width: 178px;
        height: 178px;
        display: block;
    }
}

</style>