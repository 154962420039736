<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <main class="page">
        <div class="layout page-title">
            <div class="title">公司简介</div>
            <div class="subtit">COMPANY PROFILE</div>
        </div>
        <div class="layout desc-box" v-html="profile.value ">
        </div>

        <!-- 学历版块 -->
        <div class="wsc-f7f8fa">
            <div class="layout edu-box">
                <div class="layout-title">学历版块</div>
                <el-row class="content">
                    <el-col :span="8" class="item slate5-shadow">
                        <h4 class="title">专科学历</h4>
                        <div class="cont">
                            <p class="line" v-for="item in edu_zk">
                                <b>{{item.label}}：</b>{{item.value}}
                            </p>
                        </div>
                        <div class="img-box">
                            <img class="img" src="../assets/svg/about--edu-img1.svg" alt="">
                        </div>
                    </el-col>
                    <el-col :span="8" class="item slate5-shadow">
                        <h4 class="title">本科学历</h4>
                        <div class="cont">
                            <p class="line" v-for="item in edu_bk">
                                <b>{{item.label}}：</b>{{item.value}}
                            </p>
                        </div>
                        <div class="img-box">
                            <img class="img" src="../assets/svg/about--edu-img2.svg" alt="">
                        </div>
                    </el-col>
                    <el-col :span="8" class="item slate5-shadow">
                        <h4 class="title">研究生学历</h4>
                        <div class="cont">
                            <p class="line" v-for="item in edu_yjs">
                                <b>{{item.label}}：</b>{{item.value}}
                            </p>
                        </div>
                        <div class="img-box">
                            <img class="img" src="../assets/svg/about--edu-img3.svg" alt="">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <!-- 出国留学版块 -->
        <div class="layout study-box">
            <div class="layout-title">出国留学版块</div>
            <div class="content">
                <div class="item">
                    <div class="img-box">
                        <img src="../assets/images/about-study1.png" alt="">
                    </div>
                    <div class="cont">
                        <h4 class="tit">本科出国留学</h4>
                        <p class="desc">一年制出国留学、二年制出国留学、三年制出国留学、四年制出国留学、授权国内进行教学学分同步、招生宣传合作</p>
                    </div>
                </div>
                <div class="item">
                    <div class="cont">
                        <h4 class="tit">研究生出国留学</h4>
                        <p class="desc">一年制出国留学、二年制出国留学、授权国内进行教学学分同步、招生宣传合作</p>
                    </div>
                    <div class="img-box">
                        <img src="../assets/images/about-study2.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 就业版块 -->
        <div class="layout work-box">
            <div class="text-center-title">就业无忧</div>
            <div class="content">
                <div class="item"><b>事业</b>单位就业</div>
                <div class="item"><b>国企</b>单位就业</div>
                <div class="item"><b>央企</b>就业</div>
                <div class="item"><b>大型企业</b>单位就业</div>
                <div class="item"><b>技能人才</b>就业</div>
                <div class="item"><b>专项</b>就业安置</div>
            </div> 
        </div>

        <!-- 短期培训版块 -->
        <div class="wsc-f7f8fa">
            <div class="layout train-box">
                <div class="layout-title">短期培训版块</div>
                <div class="content">
                    <div class="item">
                        <h4 class="title">新媒体运营</h4>
                        <p class="desc">文案策划、社交媒体管理、数据分析、活动策划、广告投放等</p>
                    </div>
                    <div class="item">
                        <h4 class="title">中医类短培</h4>
                        <p class="desc">大量知识点供你选择</p>
                    </div>
                    <div class="item">
                        <h4 class="title">平面设计</h4>
                        <p class="desc">美学、色彩、构图、排版</p>
                    </div>
                    <div class="item">
                        <h4 class="title">UI及电商设计</h4>
                        <p class="desc">视觉效果，色彩搭配、视觉交互</p>
                    </div>
                    <div class="item">
                        <h4 class="title">编程开发</h4>
                        <p class="desc">编程语言、数据结构、算法、数据库、网络通信、操作系统</p>
                    </div>
                    <div class="item">
                        <h4 class="title">云计算</h4>
                        <p class="desc">互联网提供计算、存储、网络和应用等服务</p>
                    </div>
                    <div class="item">
                        <h4 class="title">机电类短培</h4>
                        <p class="desc">大量知识点供你选择</p>
                    </div>
                    <div class="item">
                        <h4 class="title">SEO短培</h4>
                        <p class="desc">优化网站内容、实现UV与PV的提升</p>
                    </div>
                </div> 
            </div>
        </div>

        <!-- 自强不息 -->
        <div class="layout say-box">
            <h4>自强不息 厚德载物</h4>
            <p>中国教育网，助力求学者圆求学梦</p>
        </div>
    </main>
</template>
<script>
import Api from "@/Api.js";
export default {
    data() {
        return {
            profile: '',
            edu_zk: [],
            edu_bk: [],
            edu_yjs: [],
        }
    },
    methods: {
        getDataInit(){
            let that = this;
            that.id = this.$route.query.id;
            Api.Config.CompanyProfile().then((res) => {
                if (res.data.code == 1) {
                    that.profile = res.data.data.profile;
                    that.edu_zk = res.data.data.edu_zk.options_list;
                    that.edu_bk = res.data.data.edu_bk.options_list;
                    that.edu_yjs = res.data.data.edu_yjs.options_list;
                    console.log(that.edu_zk)
                }
            })
        },
    },
    mounted() {
        this.getDataInit();
    },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
    padding: 20px 0;
    min-height: calc(100vh - 300px);
}

.page-title{
    padding: 20px 0;

    .title{
        margin-left: 10px;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 10px;
        text-align: center;
    }

    .subtit{
        color: #D11A2D;
        text-align: center;
        margin-top: 5px;
    }
}



//简介
.desc-box{ 
    padding: 20px 0;
    border-top: 2px solid #D11A2D;

    p{
        margin: 5px 0;
        line-height: 30px;
    }
}

//学历版块
.edu-box{
    padding: 40px 0;

    .content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item{
        flex: 1;
        height: 590px;
        margin-right: 20px;
        padding-top: 30px;
        background: #ffffff;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .item:last-child{
        margin-right: 0;
    }

    .item .title{
        padding: 0 30px;
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .item .cont{
        padding: 0 30px;
        margin-top: 15px;
    }

    .item .cont .line{
        margin: 5px 0;
        line-height: 30px;

        b{
            color: #D11A2D;
        }
    }

    .item .img-box{
        width: 100%;
        padding: 0 30px;
    } 

    .item .img-box img{
        width: 100%;
        height: auto;
    }

}


// 出国留学版块
.study-box{
    padding: 50px 0;

    .item{
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cont{
        width: 50%;
        padding: 30px;
        height: 100%;
        background: #f7f8fa;
    }

    .cont .tit{
        color: #D11A2D;
        font-size: 22px;
        font-weight: bold;
        margin: 0;
        line-height: 40px;
    }

    .cont .desc{
        line-height: 30px;
    }

    .img-box{
        width: 50%;
        height: 100%;
        background: #ccc;
    }
}

// 就业版块
.work-box{
    padding: 35px 0;

    .text-center-title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        padding-left: 10px;
        color: #D11A2D;
        margin-bottom: 30px;
    }
    .content{
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item{
        width: 180px;
        height: 180px;
        border-radius: 180px;
        border: solid 8px #ecdedd;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 30px;
        letter-spacing: 2px;

        b{
            color: #D11A2D;
        }
    }
}

// 短期培训版块
.train-box{
    padding: 35px 0;

    .content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .item{
        width: 282px;
        padding: 20px;
        border-radius: 5px;
        height: 340px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .title{
        font-size: 20px;
        line-height: 40px;
        margin: 0;
        color: #D11A2D;
    }

    .desc{
        margin: 10px 0;
        font-size: 16px;
    }

    .item:nth-child(1){
        background: #ffffff url(../assets/images/train-img1.png) no-repeat center bottom;
    }

    .item:nth-child(2){
        background: #ffffff url(../assets/images/train-img2.png) no-repeat center bottom;
    }

    .item:nth-child(3){
        background: #ffffff url(../assets/images/train-img3.png) no-repeat center bottom;
    }
    
    .item:nth-child(4){
        background: #ffffff url(../assets/images/train-img4.png) no-repeat center bottom;
    }

    .item:nth-child(5){
        background: #ffffff url(../assets/images/train-img5.png) no-repeat center bottom;
    }

    .item:nth-child(6){
        background: #ffffff url(../assets/images/train-img6.png) no-repeat center bottom;
    }

    .item:nth-child(7){
        background: #ffffff url(../assets/images/train-img7.png) no-repeat center bottom;
    }

    .item:nth-child(8){
        background: #ffffff url(../assets/images/train-img8.png) no-repeat center bottom;
    }
}

.say-box{
    padding: 50px;

    h4{
        margin: 0;
        font-size: 32px;
        text-align: center;
        color: #D11A2D;
        letter-spacing: 10px;
    }
    p{
        margin-top: 25px;
        font-size: 22px;
        letter-spacing: 10px;
        text-align: center;
    }
}


</style>