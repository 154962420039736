import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LocationPCity:[],
    loginStatus: false,
    userToken: '',
    userTrueAuth: false,
    seniorState: [
      {
        value: 1,
        label: '在校',
      },{
        value: 2,
        label: '毕业',
      }
    ],
    educationData:[
      {
        value: 1,
        label: '初中及以下',
      },{
        value: 2,
        label: '中专/中技',
      },{
        value: 3,
        label: '高中',
      },{
        value: 4,
        label: '大专',
      },{
        value: 5,
        label: '本科',
      },{
        value: 6,
        label: '硕士',
      },{
        value: 7,
        label: '博士',
      },
    ],
    jobSearchStatus:[
      {
        value: 1,
        label: '离职-随时到岗',
      },{
        value: 2,
        label: '在职-月内到岗',
      },{
        value: 3,
        label: '在职-考虑机会',
      },{
        value: 4,
        label: '在职-暂不考虑',
      }
    ],
    user_info:{
      id:'',
      mobile: '',
      avatar:'',
      username:'',
      nickname:'',
      score:'',
      prevtime:'',
      province_name: '',
      province_id: '',
      city_name: '',
      city_id: '',
      createtime: '',
      truename: '',
      identify_num: '',
    },
    Config: {
      // 本地环境
      Api_Url: 'https://admin.zhongguojiaoyunet.com/api/',
      // 资源url
      Assets_Url: 'https://admin.zhongguojiaoyunet.com/',
      // 生产环境
      // Api_Url: 'http://open.wfsever.cn:23000',
    }
  },
  getters: {
    loginStatus: state => Boolean(state.token),
    myState: state => {
      // 返回状态或基于状态的计算结果
      return state.LocationPCity;
    }
  },
  mutations: {
    setToken(state,token){
      state.userToken = token;
      if(token){
        state.loginStatus = true;
        localStorage.setItem('userToken', token);
        localStorage.setItem('loginStatus', true);
      }
    },
    setLocationPCity(state,data){
      state.LocationPCity = data;
    },
    setUserInfo(state,userInfo){
      state.user_info = userInfo;
      if(userInfo.truename && userInfo.identify_num){
        state.userTrueAuth = true;
      }
    },
    SetData(state, newValue, obj) {
      state[obj] = newValue
    },

  },
  actions: {

  },
  modules: {

  }
})
