<template>
  <Map ref="map" @updateLocation="updateLocation" />
</template>

<script>
import Map from '@/components/views/modelgoade.vue'
//初始数据
export default {
  components: {
    Map,
  },
  name:"Selectgaode"
  ,methods:{
    // 点击地图后得到位置信息
    updateLocation(Addr) {

      console.log(Addr)
    }
  }
};
</script>