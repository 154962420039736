<template>
  <main class="page">
    <div class="layout">
      <el-row>
        <!-- 详情 -->
        <el-col :span="18">
          <div class="content-box">
            <h1>{{ detailData.title }}</h1>
            <p>中国教育网 | 勋鸿助学金申请服务平台 | {{ detailData.date }}</p>
            <h2>{{ detailData.title }}</h2>
            <section>
              <h3>政策介绍:</h3>
              {{ detailData.content}}
            </section>
          </div>
        </el-col>

        <!-- 推荐 -->
        <el-col :span="6">
          <div class="list-box">
            <div class="title">相关推荐</div>
            <div class="content">
              <div class="item" v-for="item in recommendList" :key="item.id">
                <span class="txt" @click="herfBusinn(item.ID)">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </main>
</template>

<script>
import Api from "@/Api.js";
export default {
  data() {
    return {
      id: '',
      recommendList: [],
      detailData: {
        admin: {
          'id': '',
          'nickname': '',
          'avatar': '',
        }
      },
    }
  },
  methods: {
    //数据填充
    getDetail() {
      let that = this;
      Api.Nationalpolicy.GetArticlesDetail({
        id: that.id,
      }).then((res) => {
        Api.Nationalpolicy.GetArticleslist({
          edu_areaID:res.data.edu_areaID
        }).then(res1 => {
          that.recommendList = res1.data.data.filter(item => item.ID !==  res.data.ID );
          that.recommendList = that.recommendList.slice(0, 10);
          that.detailData = res.data;
        })
      })
    },
    herfBusinn(id) {
      this.id = id;
      this.getDetail();
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  mounted() {

  }
}
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  padding: 20px 0;
  background: #f7f8fa;
}

.content-box{
  background: #ffffff;
  border: 1px solid #f1f1f1;
  min-height: calc(100vh - 300px);
  position: relative;
  padding: 20px;
  margin-bottom: 20px; /* 添加底部间距 */
}

.list-box{
  width: 100%;
  margin-left: 20px;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #f1f1f1; /* 添加边框 */
}

.title{
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px; /* 添加底部间距 */
}

.content{
  padding: 10px 0;
}

.item{
  display: block;
  width: 100%;
  padding: 10px 20px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* 添加底部间距 */
}

.txt{
  margin-left: 5px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #333333;
  text-decoration: none;
  flex-grow: 1; /* 让文本填满剩余空间 */
}

.item:hover .txt{
  color: #D11A2D;
}

.item::before{
  content: '';
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #D11A2D;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; /* 添加右边距 */
}

/* 移除固定的伪元素内容，改为动态生成 */
.item:nth-child(1)::before{ content: '1'!important; }
.item:nth-child(2)::before{ content: '2'!important; }
.item:nth-child(3)::before{ content: '3'!important; }
.item:nth-child(4)::before{ content: '4'!important; }
.item:nth-child(5)::before{ content: '5'!important; }
.item:nth-child(6)::before{ content: '6'!important; }
.item:nth-child(7)::before{ content: '7'!important; }
.item:nth-child(8)::before{ content: '8'!important; }
.item:nth-child(9)::before{ content: '9'!important; }
.item:nth-child(10)::before{ content: '10'!important; }

.content-box h1, .content-box h2, .content-box h3 {
  color: #333;
  font-weight: bold;
  margin-top: 20px; /* 标题顶部间距 */
}

.content-box h1 {
  font-size: 24px;
}

.content-box h2 {
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.content-box h3 {
  font-size: 18px;
}

.content-box p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 10px; /* 段落顶部间距 */
}

.content-box img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px; /* 图片顶部间距 */
}
</style>