<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="app">
    <hader v-if="$store.state.loginStatus"></hader>

    <navs v-if="$store.state.loginStatus"></navs>
    
    <router-view></router-view>

    <slider></slider>

    <footers></footers>
  </div>
</template>

<script>
import Api from "@/Api.js";
import hader from '@/components/hader.vue';
import navs from '@/components/navs.vue';
import slider from '@/components/slider.vue';
import footers from '@/components/footer.vue';

export default {
  data() {
    return {
      
    }
  },
  components: {
    hader,
    navs,
    slider,
    footers
  },
  mounted() {
  
  },
  methods: {
    
  },
}
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
#app{
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
}

.el-footer {
  width: 100%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1440px) {
    .el-header,
    .el-footer{
        width: 100%;
        min-width: 1260px;
    }
}
</style>